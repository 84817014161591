import {
  mergeStyles,
  Stack,
} from '@fluentui/react';
import React, {
  FunctionComponent,
} from 'react';

const cardStyles = mergeStyles({
  marginRight: '26px',
  minHeight: '200px',
  width: '242px',
  minWidth: '100px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  display: 'flex',
  padding: '16px 32px',
});

const infoStyles = mergeStyles({
  margin: 'auto',
  textAlign: 'center',
});

const textStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '18px',
});

const embellishedTextStyles = mergeStyles({
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '40px',
  color: '#0078D4',
});

interface OverviewCardProps {
  firstLine: string,
  secondLine: string,
  thirdLine: string,
  fourthLine?: string,
  fifthLine?: string,
}

export const OverviewCard: FunctionComponent<OverviewCardProps> = (props: OverviewCardProps) => (
  <div className={cardStyles}>
    <Stack className={infoStyles}>
      <Stack.Item className={textStyles}>
        {props.firstLine}
      </Stack.Item>
      <Stack.Item className={embellishedTextStyles}>
        {props.secondLine}
      </Stack.Item>
      <Stack.Item className={textStyles}>
        {props.thirdLine}
      </Stack.Item>
      {props.fourthLine && (
        <Stack.Item className={textStyles}>
          {props.fourthLine}
        </Stack.Item>
      )}
      {props.fifthLine && (
        <Stack.Item className={textStyles}>
          {props.fifthLine}
        </Stack.Item>
      )}
    </Stack>
  </div>
);
