import React, { FunctionComponent } from 'react';
import { mergeStyles } from '@fluentui/react';

export interface TableRendererProps {
    children: Array<React.ReactNode>;
  }

const tableStyles = mergeStyles({
  borderCollapse: 'collapse !important',
  th: {
    padding: '10px',
    border: '1px solid #000 !important',
  },
  td: {
    padding: '10px',
    border: '1px solid #000 !important',
  },
});

export const TableRenderer: FunctionComponent<TableRendererProps> = ({ children }) => (
  <table className={tableStyles}>
    {children}
  </table>
);
