import React, { createContext } from 'react';
import { LoadingState } from '../../models/loadingState';

export interface ContextDataObject<Type> {
  contextData: Type | undefined,
  loadingState: LoadingState,
}

export interface HeroContentContextProps {
  setShowOrganizationDropdown: (displayOrganizationDropdown: boolean) => void,
  getShowOrganizationDropdown: () => boolean,
  getHeroContent: () => React.ReactNode,
  setHeroContent: (content: React.ReactNode) => void,
  setShowHero: (showHero: boolean) => void,
  getShowHero: () => boolean,
}

export const HeroContentContext = createContext<HeroContentContextProps>({
  getShowOrganizationDropdown: (): boolean => false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setShowOrganizationDropdown: (showOrganizationDropdown: boolean): void => {
    // Do nothing for the base case.
  },
  // do nothing for the base case
  getHeroContent: (): React.ReactNode => <></>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setHeroContent: (content: React.ReactNode): void => {
    // Do nothing for the base case.
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setShowHero: (showHero: boolean): void => {
    // Do nothing for the base case.
  },
  getShowHero: (): boolean => false,
});

export const HeroContentConsumer = HeroContentContext.Consumer;
