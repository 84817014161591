import { Claim } from 'models/claim';
import React, {
  FunctionComponent, useContext, useEffect, useState,
} from 'react';
import {
  Route, RouteProps,
} from 'react-router-dom';
import { ConfigContext } from 'components/configProvider/configContext';
import { AccessDenied } from '../../pages/accessDenied/accessDenied';
import { AuthContext } from '../authProvider/authContext';
import { CenteredProgressDots } from '../progressDots/progressDots';

export interface ProtectedRouteProps extends RouteProps{
  claims: Claim[],
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({ children, ...rest }) => {
  const authContext: any = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const isAgc = configContext.serverConfig?.isAgc;
  const [isUserAuthenticated, setAuthenticationStatus] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const checkAuthenticated = async () => {
      const isAuthenticated = await authContext.isAuthenticated();
      if (!isAuthenticated) {
        authContext.signin();
      }
      setAuthenticationStatus(isAuthenticated);
    };
    checkAuthenticated();
  }, [authContext]);

  const renderFunc = () => {
    if (!isUserAuthenticated && !isAgc) {
      return <AccessDenied />;
    }

    // If childNodes have rendered return them
    return children || <CenteredProgressDots />;
  };

  return (isUserAuthenticated || isAgc ? (
    <Route
      {...rest}
      render={renderFunc}
    />
  ) : (
    <></>
  ));
};
