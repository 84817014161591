import React from 'react';
import { mergeStyles } from '@fluentui/react';
import { OrderBar } from 'components/orderBar/orderBar';
import { Banner } from '../../components/banner/banner';
import { PageNavBar } from '../../components/pageNavigation/pageNavigation';
import { Overview } from '../../components/overview/overview';
import { WhyJWCC } from '../../components/whyJWCC/whyJWCC';
import { GettingStarted } from '../../components/gettingStarted/gettingStarted';
import { CloudSupportServices } from '../../components/cloudSupportServices/cloudSupportServices';
import { FAQs } from '../../components/faqs/faqs';
import { Footer } from '../../components/footer/footer';

const textWrapperStyles = mergeStyles({
  zIndex: 1,
  top: 0,
  position: 'absolute',
  paddingTop: '41px',
  paddingLeft: '125px',
  minWidth: '256px',
});
const textStyles = mergeStyles({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '30px',
});

const topHeaderStyle = mergeStyles({
  lineHeight: 1,
});

const bottomHeaderStyle = mergeStyles({
  paddingTop: '0.5em',
  lineHeight: '48px',
});

const pageWrapper = mergeStyles({
  position: 'absolute',
  width: '100%',
});

export const LandingPage: React.FunctionComponent = () => (
  <div className={pageWrapper}>
    <Banner>
      <div className={textWrapperStyles}>
        <h1>
          <div className={topHeaderStyle}>Microsoft and JWCC:</div>
          <div className={bottomHeaderStyle}>Secure, Mission-ready Cloud for DoD</div>
        </h1>
        <div className={textStyles}>
          Providing those who defend the United States access to the nation’s most
        </div>
        <div className={textStyles}>
          modern cloud infrastructure for classified and non-classified operations
        </div>
      </div>
    </Banner>
    <PageNavBar />
    <OrderBar />
    <Overview />
    <WhyJWCC />
    <GettingStarted />
    <CloudSupportServices />
    <FAQs />
    <Footer />
  </div>
);
