import React, { FunctionComponent, useState } from 'react';
import {
  Checkbox,
  ChoiceGroup,
  Dropdown, IButtonStyles, IChoiceGroupOption, IDropdownOption, IDropdownStyles, ILabelStyles, ITextFieldStyles, Label, MaskedTextField, mergeStyles, SharedColors, Stack, TextField,
} from '@fluentui/react';
import { ContactUsForm } from './contactUs';
import { SaveButton } from '../../components/saving/saveButton';

const inputStyles = mergeStyles({
  padding: '55px 90px 0',
});

const wrapperStyles = mergeStyles({
  display: 'flex',
});

const reqiuredMessage = mergeStyles({
  color: SharedColors.red20,
  padding: '10px 0',
});

const outerStackStyles = mergeStyles({
  width: '100%',
});

const innerStackStyles = mergeStyles({
  width: '50%',
  margin: 'auto',
});

const stackItemStyles = mergeStyles({
  marginBottom: '15px',
});

const dropdownTextFieldStyles = mergeStyles({
  marginTop: '8px',
});

const buttonStyle = mergeStyles({
  borderRadius: '4px',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  margin: '46px 0 100px',
});

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    padding: '8px 16px 6px !important',
    height: 'unset !important',
  },
};

const fieldStyle: Partial<ITextFieldStyles> = {
  subComponentStyles: {
    label: {
      root: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  },
  fieldGroup: {
    selectors: {
      'input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  field: {
    selectors: {
      '&::placeholder': {
        fontStyle: 'normal !important',
      },
    },
  },
};

const labelStyle: Partial<ILabelStyles> = {
  root: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
};

const subLabelStyles = mergeStyles({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  marginBottom: '5px',
});

const indentedFieldStyle: Partial<ITextFieldStyles> = {
  root: {
    marginTop: '5px',
    marginLeft: '24px',
  },
  subComponentStyles: {
    label: {
      root: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  },
};

const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    label: {
      fontWeight: 600,
      fontSize: '14px !important',
      lineHeight: '20px',
    },
  },
};

const horizontalRuleStyles = mergeStyles({
  borderTop: '1px solid #EFEFEF',
  lineHeight: 1,
  margin: '32px 0',
});

interface ContactUsFormProps {
  onFieldChange(orderFormFields: Partial<ContactUsForm>): void,
  onSubmit(): void,
  showRequiredMessage?: string,
  isSubmitting: boolean,
}

const roleOptions: IDropdownOption[] = [
  { key: 'contractor', text: 'Government contractor' },
  { key: 'employee1', text: 'Government employee' },
  { key: 'employee2', text: 'Government employee - procurement/acquisition' },
  { key: 'employee3', text: 'Government employee - technical' },
  { key: 'other', text: 'Other' },
];

const governmentAffiliationOptions: IDropdownOption[] = [
  { key: '1', text: 'Air Combat Command (ACC)' },
  { key: '2', text: 'Air Force' },
  { key: '3', text: 'Air Force Global Strike Command (AFGSC)' },
  { key: '4', text: 'Air Force Intelligence, Surveillance, and Reconnaissance' },
  { key: '5', text: 'Air Force Life Cycle Management Center (AFLCMC)' },
  { key: '6', text: 'Air Force Materiel Command (AFMC)' },
  { key: '7', text: 'Air Mobility Command (AMC)' },
  { key: '8', text: 'Army' },
  { key: '9', text: 'Army Forces Command (FORSCOM)' },
  { key: '10', text: 'Army Futures Command (AMC)' },
  { key: '11', text: 'Army Intelligence (G-2)' },
  { key: '12', text: 'Army Materiel Command (AMC)' },
  { key: '13', text: 'Army Training and Doctrine Command (TRADOC)' },
  { key: '14', text: 'Coast Guard' },
  { key: '15', text: 'Coast Guard - Intelligence' },
  { key: '16', text: 'Defense Advanced Research Projects Agency (DARPA)' },
  { key: '17', text: 'Defense Commissary Agency (DeCA)' },
  { key: '18', text: 'Defense Contract Audit Agency (DCAA)' },
  { key: '19', text: 'Defense Contract Management Agency (DCMA)' },
  { key: '20', text: 'Defense Counterintelligence and Security Agency (DCSA)' },
  { key: '21', text: 'Defense Finance and Accounting Services (DFAS)' },
  { key: '22', text: 'Defense Health Agency (DHA)' },
  { key: '23', text: 'Defense Information Systems Agency - Special Access Program (DISA - SAP)' },
  { key: '24', text: 'Defense Information Systems Agency (DISA)' },
  { key: '25', text: 'Defense Intelligence Agency (DIA)' },
  { key: '26', text: 'Defense Legal Services Agency (DLSA)' },
  { key: '27', text: 'Defense Logistics Agency (DLA)' },
  { key: '28', text: 'Defense Media Activity (DMA)' },
  { key: '29', text: 'Defense POW/MIA Accounting Agency (DPAA)' },
  { key: '30', text: 'Defense Security Cooperation Agency (DSCA)' },
  { key: '31', text: 'Defense Technical Information Center (DTIC)' },
  { key: '32', text: 'Defense Technology Security Administration (DTSA)' },
  { key: '33', text: 'Defense Threat Reduction Agency (DTRA)' },
  { key: '34', text: 'Department of Defense - Joint Chiefs of Staff' },
  { key: '35', text: 'Department of Defense - Office of Local Defense Community Cooperation (OLDCC)' },
  { key: '36', text: 'Department of Defense - Washington Headquarters Services (WHS)' },
  { key: '37', text: 'Department of Defense Chief Information Officer (DoD CIO)' },
  { key: '38', text: 'Department of Defense Education Activity (DoDEA)' },
  { key: '39', text: 'Department of Defense Human Resource Activity (DHRA)' },
  { key: '40', text: 'Department of Defense Test Resource Management Center (TRMC)' },
  { key: '41', text: 'Joint Special Operations Command (JSOC)' },
  { key: '42', text: 'Marine Corps' },
  { key: '43', text: 'Marine Corps Intelligence Activity (MCIA)' },
  { key: '44', text: 'Missile Defense Agency (MDA)' },
  { key: '45', text: 'National Geospatial-Intelligence Agency (NGA)' },
  { key: '46', text: 'National Guard Bureau' },
  { key: '47', text: 'National Reconnaissance Office (NRO)' },
  { key: '48', text: 'National Security Agency - Central Security Service (NSA - CSS)' },
  { key: '49', text: 'National Security Agency (NSA)' },
  { key: '50', text: 'Naval Nuclear Propulsion Program (NNPP)' },
  { key: '51', text: 'Navy' },
  { key: '52', text: 'Office of Naval Intelligence (ONI)' },
  { key: '53', text: 'Office of the Chief of Naval Operations' },
  { key: '54', text: 'Office of the Chief of Space Operations' },
  { key: '55', text: 'Office of the Inspector General of the Department of Defense ' },
  { key: '56', text: 'Office of the Secretary of Defense - Strategic Capabilities Office (OSD - SCO)' },
  { key: '57', text: 'Office of the Secretary of Defense (OSD)' },
  { key: '58', text: 'Office of the Under Secretary of Defense (Comptroller) (OUSD(C))' },
  { key: '59', text: 'Office of the Under Secretary of Defense for Acquisition & Sustainment (OUSD(A&S))' },
  { key: '60', text: 'Office of the Under Secretary of Defense for Intelligence & Security (OUSD(I&S))' },
  { key: '61', text: 'Office of the Under Secretary of Defense for Personnel & Readiness (OUSD(P&R))' },
  { key: '62', text: 'Office of the Under Secretary of Defense for Policy (OUSD(P))' },
  { key: '63', text: 'Office of the Under Secretary of Defense for Research & Engineering (OUSD(R&E))' },
  { key: '64', text: 'Pacific Air Forces (PACAF)' },
  { key: '65', text: 'Pentagon Force Protection Agency (PRPA)' },
  { key: '66', text: 'Space Development Agency (SDA)' },
  { key: '67', text: 'Space Force' },
  { key: '68', text: 'Space Force - Intelligence' },
  { key: '69', text: 'United States Africa Command (USAFRICOM)' },
  { key: '70', text: 'United States Air Forces Europe & Air Forces Africa (USAFE - AFAFRICA)' },
  { key: '71', text: 'United States Army Africa (USARAF)' },
  { key: '72', text: 'United States Army Europe (USAREUR)' },
  { key: '73', text: 'United States Army Pacific (USARPAC)' },
  { key: '74', text: 'United States Central Command (USCENTCOM)' },
  { key: '75', text: 'United States Cyber Command (USCYBERCOM)' },
  { key: '76', text: 'United States European Command (USEUCOM)' },
  { key: '77', text: 'United States Fleet Forces Command (USFLTFORCOM)' },
  { key: '78', text: 'United States Force Korea (USFK)' },
  { key: '79', text: 'United States Indo-Pacific Command (USINDOPACOM)' },
  { key: '80', text: 'United States Naval Forces Africa (NAVAF)' },
  { key: '81', text: 'United States Naval Forces Europe (NAVEUR)' },
  { key: '82', text: 'United States Northern Command (USNORTHCOM)' },
  { key: '83', text: 'United States Pacific Fleet (USPACFLT)' },
  { key: '84', text: 'United States Southern Command (USSOUTHCOM)' },
  { key: '85', text: 'United States Space Command (USSPACECOM)' },
  { key: '86', text: 'United States Special Operations Command (USSOCOM)' },
  { key: '87', text: 'United States Strategic Command (USSTRATCOM)' },
  { key: '88', text: 'United States Transportation Command (USTRANSCOM)' },
];

const customerStatusOptions: IChoiceGroupOption[] = [
  { key: 'currentCustomer', text: 'Current customer' },
  { key: 'prospectiveCustomer', text: 'Prospective customer' },
  { key: 'other2', text: 'Other' },
];

const cloudUsage: string[] = [
  'Do not use Cloud today',
  'Evaluating/experimenting with Cloud',
  'Run dev/test workloads on Cloud',
  'Run a production workload on Cloud',
  'Run multiple production workloads on Cloud',
];

const useCase: string[] = [
  'AI & machine learning',
  'Batch processing',
  'Big data, analytics, & business intelligence',
  'Business applications - Amazon Web Services (AWS)',
  'Business applications - Oracle',
  'Business applications - Other',
  'Business applications - SAP',
  'Content delivery',
  'Developer operations (DevOps)',
  'Gaming',
  'Internet of things (IoT)',
  'Learning & certification',
  'Media services',
  'Migration',
  'Mobile applications',
  'Other',
  'Storage & backup',
  'Website & web apps',
];

const classifications: string[] = [
  'Unclassified',
  'Secret',
  'Top secret',
  'Other',
];

const stackTokens = { childrenGap: 8 };

export interface ContactUsFormArrayFields {
  cloudUsage: string[],
  useCase: string[],
  projectClassifications: string[],
}

export interface ContactUsFormTextFields {
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string,
  role: string,
  governmentAffiliation: string,
  customerStatus: string,
  projectDescription: string,
}

export const ContactUsFormFields: FunctionComponent<ContactUsFormProps> = (props) => {
  const {
    onFieldChange, showRequiredMessage, onSubmit, isSubmitting,
  } = props;
  const [contactUsFormFields, setContactUsFormFields] = useState<Partial<ContactUsForm>>();
  const [otherCustomerStatusText, setOtherCustomerStatusText] = useState<string>('');
  const [otherRoleText, setOtherRoleText] = useState<string>('');
  const [otherClassificationText, setOtherClassificationText] = useState<string>('');
  const [otherClassificationChecked, setOtherClassificationChecked] = useState<boolean>(false);
  const other = 'Other';

  const onSave = () => {
    onSubmit();
  };

  const onTextChange = (field: string, item?: string) => {
    if (!item) {
      return;
    }

    updateTextFields(field, item);
  };

  const onDropdownChange = (field: string, item: IDropdownOption | undefined) => {
    if (!item) {
      return;
    }

    let { text } = item;

    // NOTE: This only works because only one dropdown has other
    // If we ever add more, we will have to pull this out into a component
    if (text.includes(other) && otherRoleText !== '') {
      text = otherRoleText;
    }

    updateTextFields(field, text);
  };

  const onRadioButtonChanged = (field: string, item: IChoiceGroupOption | undefined) => {
    if (!item) {
      return;
    }

    let { text } = item;

    // NOTE: This only works because only one radio button group has other
    // If we ever add more, we will have to pull this out into a component
    if (text.includes(other) && otherCustomerStatusText !== '') {
      text = otherCustomerStatusText;
    }

    updateTextFields(field, text);
  };

  // NOTE: All of this logic would be lovely to pull out into a component one day when we have time so we can reuse
  const onCheckboxChange = (field: string, itemChecked: string, checked: boolean | undefined) => {
    // Since it is a boolean, cannot just check for !checked
    if (checked === undefined) {
      return;
    }

    const updatedFields = { ...contactUsFormFields };
    let items = updatedFields[field as keyof ContactUsFormArrayFields];

    // If a user has not selected anything yet, the field will be undefined - create an empty array
    if (!items) {
      items = [];
    }

    // NOTE: This only works because only one checkbox group has other
    // If we ever add more, we will have to pull this out into a component
    if (itemChecked.includes(other)) {
      setOtherClassificationChecked(checked);
    }

    // If checked, add to the array
    if (checked) {
      items.push(itemChecked);
    } else {
      // otherwise, they deselected it, find it and remove it
      // In the event this is the "Other" field, the name won't match exactly
      // Thus, we need a different check in this case
      let index = -1;
      if (itemChecked.includes(other)) {
        index = items.findIndex((item) => item.includes(other));
      } else {
        index = items.findIndex((item) => item === itemChecked);
      }

      if (index === -1) {
        return; // nothing to remove
      }

      items.splice(index, 1);
    }

    updateArrayFields(field, items);
  };

  // NOTE: All of this logic would be lovely to pull out into a component one day when we have time so we can reuse
  const onOtherClassificationTextChange = (field: string, newValue?: string) => {
    // On updating the Other text box, we need to either add that value or update it
    if (!newValue) {
      return;
    }

    setOtherClassificationText(newValue);

    // If the other box isn't selected, do not add to array
    if (!otherClassificationChecked) {
      return;
    }

    const updatedFields = { ...contactUsFormFields };
    let items = updatedFields[field as keyof ContactUsFormArrayFields];

    // If a user has not selected anything yet, the field will be undefined - create an empty array
    if (!items) {
      items = [];

      // If the array is empty, we can just push the value and move on
      items.push(newValue);
      updateArrayFields(field, items);
      return;
    }

    // If the array is not empty, we should check if an item with "Other" already exists
    const index = items.findIndex((item) => item.includes(other));

    // If not, add it
    if (index === -1) {
      items.push(newValue);
    } else {
      // If so, update it
      items[index] = newValue;
    }

    updateArrayFields(field, items);
  };

  const onOtherCustomerStatusTextChange = (field: string, newValue?: string) => {
    if (!newValue) {
      return;
    }

    setOtherCustomerStatusText(newValue);
    updateOtherTextField(field, newValue);
  };

  const onOtherRoleTextChange = (field: string, newValue?: string) => {
    if (!newValue) {
      return;
    }

    setOtherRoleText(newValue);
    updateOtherTextField(field, newValue);
  };

  const updateOtherTextField = (field: string, text: string) => {
    const updatedFields = { ...contactUsFormFields };
    const item = updatedFields[field as keyof ContactUsFormTextFields];

    // If the role is not  populated yet, return - a user must make a selection first
    if (!item) {
      return;
    }

    // If other is selected, only then update the customer status
    if (item.includes(other)) {
      updateTextFields(field, text);
    }
  };

  const updateTextFields = (field: string, item: string) => {
    const updatedFields = { ...contactUsFormFields };
    updatedFields[field as keyof ContactUsFormTextFields] = item;
    setContactUsFormFields(updatedFields);
    onFieldChange(updatedFields);
  };

  const updateArrayFields = (field: string, items: string[]) => {
    const updatedFields = { ...contactUsFormFields };
    updatedFields[field as keyof ContactUsFormArrayFields] = items;
    setContactUsFormFields(updatedFields);
    onFieldChange(updatedFields);
  };

  return (
    <div className={inputStyles}>
      {showRequiredMessage && (
        <Stack className={innerStackStyles}>
          <Stack.Item className={stackItemStyles}>
            <div className={reqiuredMessage}>{showRequiredMessage}</div>
          </Stack.Item>
        </Stack>
      )}
      <div className={wrapperStyles}>
        <Stack className={outerStackStyles}>
          <Stack className={innerStackStyles}>
            <Stack.Item className={stackItemStyles}>
              <TextField
                id="clarity_firstName"
                styles={fieldStyle}
                label="First name"
                placeholder="Enter contact first name"
                onChange={(event: any, newValue?: string) => onTextChange('firstName', newValue)}
                required
              />
            </Stack.Item>
            <Stack.Item className={stackItemStyles}>
              <TextField
                id="clarity_lastName"
                styles={fieldStyle}
                label="Last name"
                placeholder="Enter contact last name"
                onChange={(event: any, newValue?: string) => onTextChange('lastName', newValue)}
                required
              />
            </Stack.Item>
            <Stack.Item className={stackItemStyles}>
              <TextField
                id="clarity_email"
                styles={fieldStyle}
                label="Email address"
                placeholder="Enter email address"
                onChange={(event: any, newValue?: string) => onTextChange('emailAddress', newValue)}
                required
              />
            </Stack.Item>
            <Stack.Item className={stackItemStyles}>
              <MaskedTextField
                id="clarity_phone"
                styles={fieldStyle}
                label="Phone number"
                placeholder="Enter a phone number"
                mask="(999) 999 - 9999"
                onChange={(event: any, newValue?: string) => onTextChange('phoneNumber', newValue)}
                required
              />
            </Stack.Item>
            <Stack.Item className={stackItemStyles}>
              <Dropdown
                id="clarity_role"
                placeholder="Select an option"
                label="Role"
                options={roleOptions}
                styles={dropdownStyles}
                onChange={(event: any, option?: IDropdownOption<any>) => onDropdownChange('role', option)}
                required
              />
              {contactUsFormFields?.role && contactUsFormFields?.role.includes(other) && (
                <TextField
                  className={dropdownTextFieldStyles}
                  styles={fieldStyle}
                  placeholder="Enter another role"
                  onChange={(event: any, newValue?: string) => onOtherRoleTextChange('role', `${other} - ${newValue}`)}
                />
              )}
            </Stack.Item>
            <Stack.Item className={stackItemStyles}>
              <Dropdown
                placeholder="Select an option"
                label="U.S. Federal Government organization affiliation"
                options={governmentAffiliationOptions}
                styles={dropdownStyles}
                onChange={(event: any, option?: IDropdownOption<any>) => onDropdownChange('governmentAffiliation', option)}
                required
              />
            </Stack.Item>
            <Stack.Item className={stackItemStyles} id="clarity_customerStatus">
              <Label styles={labelStyle} required>
                Customer status
              </Label>
              <ChoiceGroup
                options={customerStatusOptions}
                onChange={(event: any, option?: IChoiceGroupOption) => onRadioButtonChanged('customerStatus', option)}
                required
              />
              <TextField
                styles={indentedFieldStyle}
                placeholder="Enter another status"
                onChange={(event: any, newValue?: string) => onOtherCustomerStatusTextChange('customerStatus', `${other} - ${newValue}`)}
              />
            </Stack.Item>
          </Stack>
          <div className={horizontalRuleStyles} />
          <Stack className={innerStackStyles} tokens={stackTokens}>
            <Label styles={labelStyle}>
              Level of Microsoft cloud usage (select all that apply)
            </Label>
            {cloudUsage.map((usage) => (
              <Checkbox
                key={usage}
                label={usage}
                onChange={(event: any, isChecked?: boolean) => onCheckboxChange('cloudUsage', usage, isChecked)}
              />
            ))}
          </Stack>
          <div className={horizontalRuleStyles} />
          <Stack className={innerStackStyles} tokens={stackTokens}>
            <Label styles={labelStyle}>
              Use case (select all that apply)
            </Label>
            {useCase.map((item) => (
              <Checkbox
                key={item}
                label={item}
                onChange={(event: any, isChecked?: boolean) => onCheckboxChange('useCase', item, isChecked)}
              />
            ))}
          </Stack>
          <div className={horizontalRuleStyles} />
          <Stack className={innerStackStyles}>
            <Stack.Item className={stackItemStyles}>
              <Label styles={labelStyle}>
                Tell us about your project
              </Label>
              <div className={subLabelStyles}>
                (Include Microsoft Cloud services of interest, use case(s), and/or usage estimates)
              </div>
              <TextField
                id="clarity_projectDescription"
                styles={fieldStyle}
                placeholder="Enter your answer"
                onChange={(event: any, newValue?: string) => onTextChange('projectDescription', newValue)}
                multiline
              />
            </Stack.Item>
          </Stack>
          <div className={horizontalRuleStyles} />
          <Stack className={innerStackStyles} tokens={stackTokens}>
            <Label styles={labelStyle} required>
              Security classification levels required for this project
            </Label>
            {classifications.map((classification) => (
              <Checkbox
                key={classification}
                label={classification}
                onChange={(event: any, isChecked?: boolean) => (
                  onCheckboxChange(
                    'projectClassifications',
                    classification === other && otherClassificationText !== '' ? otherClassificationText : classification,
                    isChecked,
                  )
                )}
                required
              />
            ))}
            <TextField
              styles={indentedFieldStyle}
              placeholder="Enter another classification level"
              onChange={(event: any, newValue?: string) => onOtherClassificationTextChange('projectClassifications', `${other} - ${newValue}`)}
            />
          </Stack>
          <Stack className={innerStackStyles}>
            <Stack.Item className={stackItemStyles}>
              <SaveButton
                className={buttonStyle}
                styles={buttonStyles}
                defaultText="Submit"
                saveText="Submitting..."
                onSave={onSave}
                isSaving={isSubmitting}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};
