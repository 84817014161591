import {
  Icon, IStyle, mergeStyles, mergeStyleSets,
} from '@fluentui/react';
import * as React from 'react';
import {
  useCallback,
  useState,
  useEffect,
  KeyboardEvent,
  FunctionComponent,
  useMemo,
} from 'react';

export interface IAccordionType {
  key: string
  deletable: boolean,
  header?: string,
}

export interface IExpandableListStyle {
  root?: IStyle;
  header?: IStyle;
}
interface AccordionProps {
  headerElement: JSX.Element | JSX.Element[],
  children?: JSX.Element | JSX.Element[],
  defaultIsExpanded?: boolean,
  isExpanded?: boolean,
  className?: string,
  styles?: IExpandableListStyle,
  isReadOnly?: boolean,
  ariaLabel?: string,
}

const headerStyle = {
  outline: 'none',
  display: 'flex',
  cursor: 'pointer',
} as IStyle;

const headerIconStyle = mergeStyles({
  margin: 'auto 10px auto 0',
});

const contentStyle = mergeStyles({
  margin: '10px 0',
});

export const Accordion: FunctionComponent<AccordionProps> = (props) => {
  const {
    headerElement,
    children,
    className,
    styles,
    isReadOnly,
  } = props;
  const [isExpandedInteral, setIsExpandedInternal] = useState<boolean>(props.defaultIsExpanded ?? false);
  const [isExpanded, setIsExpanded] = useState<boolean>(props.isExpanded ?? isExpandedInteral);

  useEffect(() => {
    if (props.isExpanded) {
      setIsExpanded(props.isExpanded);
      setIsExpandedInternal(props.isExpanded);
    }
  }, [props.isExpanded]);

  useEffect(() => {
    setIsExpanded(isExpandedInteral);
  }, [isExpandedInteral]);

  const toggleContentClick = useCallback(() => {
    setIsExpandedInternal((toggleContent) => !toggleContent);
  }, [setIsExpandedInternal]);

  const toggleContentKeyUp = useCallback((event: KeyboardEvent) => {
    if (event.keyCode === 32 || event.keyCode === 13) { // key code of space and enter, respectively
      setIsExpandedInternal((toggleContent) => !toggleContent);
    }
  }, [setIsExpandedInternal]);

  const classNames = useMemo(() => mergeStyleSets({
    root: [
      className,
    ],
    header: [
      styles?.header,
      headerStyle,
    ],
    readonlyHeader: [
      styles?.header,
    ],
  }), [className, styles?.header]);

  return (
    <div className={classNames.root}>
      {isReadOnly ? (
        <div className={classNames.readonlyHeader}>
          {headerElement}
        </div>
      ) : (
        <div className={classNames.header} role="button" tabIndex={0} aria-label={props.ariaLabel ? props.ariaLabel : 'Toggle Content Expansion'} aria-expanded={isExpanded} onClick={toggleContentClick} onKeyUp={toggleContentKeyUp}>
          <Icon className={headerIconStyle} iconName={isExpanded ? 'ChevronUp' : 'ChevronDown'} />
          {headerElement}
        </div>
      )}
      { isExpanded && (
        <div className={contentStyle}>
          {children}
        </div>
      )}
    </div>
  );
};
