import React, { FunctionComponent } from 'react';
import {
  mergeStyles, Stack,
} from '@fluentui/react';
import { OrderForm } from 'pages/orderForm/orderPage';
import { SupportServices, SupportServicesOrder } from '../orderForm/supportServices';

const inputStyles = mergeStyles({
  padding: '55px 90px 0',
});

const wrapperStyles = mergeStyles({
  display: 'flex',
});

const stackStyles = mergeStyles({
  width: '100%',
  selectors: {
    ':first-child': {
      marginRight: '38px',
    },
  },
});

const stackItemStyles = mergeStyles({
  marginBottom: '15px',
});

const labelStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  display: 'block',
});

const textReviewFieldStyles = mergeStyles({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  padding: '5px 0px',
  display: 'block',
});

const horizontalRuleStyles = mergeStyles({
  borderTop: '1px solid #EFEFEF',
  lineHeight: 1,
  margin: '32px 0',
});

interface OrderReviewFieldsProps {
  order: OrderForm,
  supportServices: SupportServicesOrder[],
}

const GetLabeledStackItemText = (label: string, text: string, id: string) => (
  <Stack.Item className={stackItemStyles}>
    <span className={labelStyles}>{label}</span>
    <span id={id} className={textReviewFieldStyles}>{text}</span>
  </Stack.Item>
);

export const OrderReviewContent: FunctionComponent<OrderReviewFieldsProps> = (props) => {
  const { order, supportServices } = props;

  return (
    <div className={inputStyles}>
      <div className={wrapperStyles}>
        <Stack className={stackStyles}>
          {GetLabeledStackItemText('Agency/office', order.agencyName, 'clarity_review_agencyOffice')}
          {GetLabeledStackItemText('Task order number', order.taskOrderNumber, 'clarity_review_taskOrderNumber')}
          {GetLabeledStackItemText('Task order CLIN number', order.taskOrderClin, 'clarity_review_clinNumber')}
          {GetLabeledStackItemText('Start Date', order.startDate, 'clarity_review_start_date')}
        </Stack>
        <Stack className={stackStyles}>
          {GetLabeledStackItemText('Point of contact (POC) name', order.pointOfContactName, 'clarity_review_pocName')}
          {GetLabeledStackItemText('POC phone number', order.pointOfContactPhoneNumber, 'clarity_review_pocNumber')}
          {GetLabeledStackItemText('POC email', order.pointOfContactEmail, 'clarity_review_pocEmail')}
          {GetLabeledStackItemText('Location (onsite) - if applicable', order.onSiteLocation || '', 'clarity_review_location')}
        </Stack>
      </div>
      <div className={horizontalRuleStyles} />
      <SupportServices
        supportServicesOrder={supportServices.map((ss) => ({ ...ss, id: ss.productId + ss.skuId }))}
        isDisabled
      />
    </div>
  );
};
