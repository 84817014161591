export const loginCallbackRoute = (): string => '/signin-oidc';
export const accessDeniedRoute = (): string => '/accessDenied';
export const landingRoute = (): string => '/';
export const defaultPricingCalculatorRoute = (): string => '/pricing-calculator/';
export const pricingCalculatorRoute = (): string => '/pricing-calculator/user-guide/:subpath*';
export const orderFormRoute = (): string => '/order';
export const contactUsRoute = (): string => '/contact';

export interface ServiceDetailRouteParams {
  serviceOid: string,
}
