import { IAccordionType } from 'components/accordion/accordion';
import { SupportService } from '../pages/orderForm/supportServices';

export interface SupportServiceSection extends IAccordionType {
  content: SupportService[],
  header: string,
}

export const aLaCarteNoClearance: SupportService[] = [
  {
    skuId: '0007',
    unitOfIssue: 'Hourly',
    title: 'Account Delivery Executive',
    productId: 'CFQ7TTC0M9PL',
    id: 'CFQ7TTC0M9PL-0007',
  },
  {
    skuId: '0001',
    unitOfIssue: 'Hourly',
    title: 'Associate Consultant',
    productId: 'CFQ7TTC0QK36',
    id: 'CFQ7TTC0QK36-0001',
  },
  {
    skuId: '0006',
    unitOfIssue: 'Hourly',
    title: 'Consultant',
    productId: 'CFQ7TTC0M9PM',
    id: 'CFQ7TTC0M9PM-0006',
  },
  {
    skuId: '0007',
    unitOfIssue: 'Hourly',
    title: 'Delivery Data Scientist',
    productId: 'CFQ7TTC0M9P9',
    id: 'CFQ7TTC0M9P9-0007',
  },
  {
    skuId: '0005',
    unitOfIssue: 'Hourly',
    title: 'Principal Consultant',
    productId: 'CFQ7TTC0M9PP',
    id: 'CFQ7TTC0M9PP-0005',
  },
  {
    skuId: '0001',
    unitOfIssue: 'Hourly',
    title: 'Associate Project Manager',
    productId: 'CFQ7TTC0TF45',
    id: 'CFQ7TTC0TF45-0001',
  },
  {
    skuId: '0001',
    unitOfIssue: 'Hourly',
    title: 'Project Manager',
    productId: 'CFQ7TTC0QK34',
    id: 'CFQ7TTC0QK34-0001',
  },
  {
    skuId: '0005',
    unitOfIssue: 'Hourly',
    title: 'Senior Consultant',
    productId: 'CFQ7TTC0M9PN',
    id: 'CFQ7TTC0M9PN-0005',
  },
  {
    skuId: '0005',
    unitOfIssue: 'Hourly',
    title: 'Senior Project Manager',
    productId: 'CFQ7TTC0M9PK',
    id: 'CFQ7TTC0M9PK-0005',
  },
  {
    skuId: '0007',
    unitOfIssue: 'Hourly',
    title: 'Architect',
    productId: 'CFQ7TTC0M9P7',
    id: 'CFQ7TTC0M9P7-0007',
  },
  {
    skuId: '0005',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual Instructor-Led 1 day (8 hours) Training (up to 20 students) - Per Class',
    productId: 'CFQ7TTC0QK1N',
    id: 'CFQ7TTC0QK1N-0005',
  },
  {
    skuId: '0004',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual Instructor-Led 1 day (8 hours) Training (up to 50 students) - Per Class',
    productId: 'CFQ7TTC0QK1N',
    id: 'CFQ7TTC0QK1N-0004',
  },
  {
    skuId: '0001',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Onsite Instructor-Led 1 day (8 hours) Training (up to 20 students) - Per Class',
    productId: 'CFQ7TTC0QK1N',
    id: 'CFQ7TTC0QK1N-0001',
  },
  {
    skuId: '0001',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual Subject Matter Expert(s)-Led Advisory Service - 1 day (8 hours)',
    productId: 'CFQ7TTC0QK3B',
    id: 'CFQ7TTC0QK3B-0001',
  },
  {
    skuId: '0001',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Onsite Subject Matter Expert(s)-Led Advisory Service - 1 day (8 hours)',
    productId: 'CFQ7TTC0QK3C',
    id: 'CFQ7TTC0QK3C-0001',
  },
  {
    skuId: '0001',
    unitOfIssue: 'Person/Day',
    title: 'Open Enrollment-Virtual',
    productId: 'CFQ7TTC0MH11',
    id: 'CFQ7TTC0MH11-0001',
  },
  {
    skuId: '0001',
    unitOfIssue: '1',
    title: 'Fundamentals Exam Certification',
    productId: 'CFQ7TTC0MH10',
    id: 'CFQ7TTC0MH10-0001',
  },
  {
    skuId: '0001',
    unitOfIssue: '1',
    title: 'Advanced Role-Based Exam Certification',
    productId: 'CFQ7TTC0MH0Z',
    id: 'CFQ7TTC0MH0Z-0001',
  },
];

export const tacticalNoClearance: SupportService[] = [
  {
    skuId: '0005',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Classroom TE Operator training per day - DC/Seattle-metro - Up to 5 people per class',
    productId: 'CFQ7TTC0QK1L',
    id: 'CFQ7TTC0QK1L-0005',
  },
  {
    skuId: '0006',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Classroom TE Operator Training per day - Customer CONUS Location - Up to 5 people per class',
    productId: 'CFQ7TTC0QK1L',
    id: 'CFQ7TTC0QK1L-0006',
  },
  {
    skuId: '000B',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual TE Operator Training per day - Up to 10 people per class',
    productId: 'CFQ7TTC0QK1L',
    id: 'CFQ7TTC0QK1L-000B',
  },
  {
    skuId: '0001',
    unitOfIssue: '1 year',
    title: 'Azure Stack Hub Ruggedized / Dedicated offsite support - US Citizen - CONUS - 1 yr',
    productId: 'CFQ7TTC0QK3H',
    id: 'CFQ7TTC0QK3H-0001',
  },
  {
    skuId: '0002',
    unitOfIssue: '1 year',
    title: 'Azure Stack Hub Ruggedized / Dedicated onsite support - US Citizen - CONUS - 1 yr',
    productId: 'CFQ7TTC0QK3H',
    id: 'CFQ7TTC0QK3H-0002',
  },
];

export const aLaCarteSecret: SupportService[] = [
  {
    skuId: '0006',
    unitOfIssue: 'Hourly',
    title: 'Account Delivery Executive (SECRET-cleared)',
    productId: 'CFQ7TTC0M9PL',
    id: 'CFQ7TTC0M9PL-0006',
  },
  {
    skuId: '0002',
    unitOfIssue: 'Hourly',
    title: 'Associate Consultant (SECRET-cleared)',
    productId: 'CFQ7TTC0QK36',
    id: 'CFQ7TTC0QK36-0002',
  },
  {
    skuId: '0007',
    unitOfIssue: 'Hourly',
    title: 'Consultant (SECRET-cleared)',
    productId: 'CFQ7TTC0M9PM',
    id: 'CFQ7TTC0M9PM-0007',
  },
  {
    skuId: '0006',
    unitOfIssue: 'Hourly',
    title: 'Delivery Data Scientist (SECRET-cleared)',
    productId: 'CFQ7TTC0M9P9',
    id: 'CFQ7TTC0M9P9-0006',
  },
  {
    skuId: '0006',
    unitOfIssue: 'Hourly',
    title: 'Principal Consultant (SECRET-cleared)',
    productId: 'CFQ7TTC0M9PP',
    id: 'CFQ7TTC0M9PP-0006',
  },
  {
    skuId: '0001',
    unitOfIssue: 'Hourly',
    title: 'Associate Project Manager (SECRET-cleared)',
    productId: 'CFQ7TTC0TF44',
    id: 'CFQ7TTC0TF44-0001',
  },
  {
    skuId: '0003',
    unitOfIssue: 'Hourly',
    title: 'Project Manager (SECRET-cleared)',
    productId: 'CFQ7TTC0QK34',
    id: 'CFQ7TTC0QK34-0003',
  },
  {
    skuId: '0006',
    unitOfIssue: 'Hourly',
    title: 'Senior Consultant (SECRET-cleared)',
    productId: 'CFQ7TTC0M9PN',
    id: 'CFQ7TTC0M9PN-0006',
  },
  {
    skuId: '0006',
    unitOfIssue: 'Hourly',
    title: 'Senior Project Manager (SECRET-cleared)',
    productId: 'CFQ7TTC0M9PK',
    id: 'CFQ7TTC0M9PK-0006',
  },
  {
    skuId: '0006',
    unitOfIssue: 'Hourly',
    title: 'Architect (SECRET-cleared)',
    productId: 'CFQ7TTC0M9P7',
    id: 'CFQ7TTC0M9P7-0006',
  },
  {
    skuId: '0003',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual Instructor-Led 1 day (8 hours) Training (up to 20 students) - Per Class.  holds at least a SECRET-clearance.',
    productId: 'CFQ7TTC0QK1N',
    id: 'CFQ7TTC0QK1N-0003',
  },
  {
    skuId: '0002',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual Instructor-Led 1 day (8 hours) Training (up to 50 students) - Per Class. holds at least a SECRET-clearance.',
    productId: 'CFQ7TTC0QK1N',
    id: 'CFQ7TTC0QK1N-0002',
  },
  {
    skuId: '0001',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Onsite Instructor-Led 1 day (8 hours) Training (up to 20 students) - Per Class. Holds at least a SECRET-clearance.',
    productId: 'CFQ7TTC0QK1P',
    id: 'CFQ7TTC0QK1P-0001',
  },
  {
    skuId: '0001',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual Subject Matter Expert(s)-Led Advisory Service - 1 day (8 hours). Holds at least a SECRET-clearance.',
    productId: 'CFQ7TTC0QK3D',
    id: 'CFQ7TTC0QK3D-0001',
  },
  {
    skuId: '0001',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Onsite Subject Matter Expert(s)-Led Advisory Service - 1 day (8 hours). Holds at least a SECRET-clearance.',
    productId: 'CFQ7TTC0QK3F',
    id: 'CFQ7TTC0QK3F-0001',
  },
];

export const tacticalSecret: SupportService[] = [
  {
    skuId: '0004',
    unitOfIssue: '1 year',
    title: 'Azure Stack Hub Ruggedized / Dedicated offsite support - Cleared -US Citizen - CONUS - 1 yr',
    productId: 'CFQ7TTC0QK3H',
    id: 'CFQ7TTC0QK3H-0004',
  },
  {
    skuId: '0003',
    unitOfIssue: '1 year',
    title: 'Azure Stack Hub Ruggedized / Dedicated onsite support - Cleared - US Citizen - CONUS - 1 yr',
    productId: 'CFQ7TTC0QK3H',
    id: 'CFQ7TTC0QK3H-0003',
  },
];

export const aLaCarteTopSecret: SupportService[] = [
  {
    skuId: '0005',
    unitOfIssue: 'Hourly',
    title: 'Account Delivery Executive (TS-cleared)',
    productId: 'CFQ7TTC0M9PL',
    id: 'CFQ7TTC0M9PL-0005',
  },
  {
    skuId: '0003',
    unitOfIssue: 'Hourly',
    title: 'Associate Consultant (TS-cleared)',
    productId: 'CFQ7TTC0QK36',
    id: 'CFQ7TTC0QK36-0003',
  },
  {
    skuId: '0005',
    unitOfIssue: 'Hourly',
    title: 'Consultant (TS-cleared)',
    productId: 'CFQ7TTC0M9PM',
    id: 'CFQ7TTC0M9PM-0005',
  },
  {
    skuId: '0005',
    unitOfIssue: 'Hourly',
    title: 'Delivery Data Scientist (TS-cleared)',
    productId: 'CFQ7TTC0M9P9',
    id: 'CFQ7TTC0M9P9-0005',
  },
  {
    skuId: '0007',
    unitOfIssue: 'Hourly',
    title: 'Principal Consultant (TS-cleared)',
    productId: 'CFQ7TTC0M9PP',
    id: 'CFQ7TTC0M9PP-0007',
  },
  {
    skuId: '0001',
    unitOfIssue: 'Hourly',
    title: 'Associate Project Manager (TS-cleared)',
    productId: 'CFQ7TTC0TF4M',
    id: 'CFQ7TTC0TF4M-0001',
  },
  {
    skuId: '0002',
    unitOfIssue: 'Hourly',
    title: 'Project Manager (TS-cleared)',
    productId: 'CFQ7TTC0QK34',
    id: 'CFQ7TTC0QK34-0002',
  },
  {
    skuId: '0007',
    unitOfIssue: 'Hourly',
    title: 'Senior Consultant (TS-cleared)',
    productId: 'CFQ7TTC0M9PN',
    id: 'CFQ7TTC0M9PN-0007',
  },
  {
    skuId: '0007',
    unitOfIssue: 'Hourly',
    title: 'Senior Project Manager (TS-cleared)',
    productId: 'CFQ7TTC0M9PK',
    id: 'CFQ7TTC0M9PK-0007',
  },
  {
    skuId: '0005',
    unitOfIssue: 'Hourly',
    title: 'Architect (TS-cleared)',
    productId: 'CFQ7TTC0M9P7',
    id: 'CFQ7TTC0M9P7-0005',
  },
  {
    skuId: '0007',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual Instructor-Led 1 day (8 hours) Training (up to 20 students) - Per Class. Holds at least a TOP SECRET-clearance.',
    productId: 'CFQ7TTC0QK1N',
    id: 'CFQ7TTC0QK1N-0007',
  },
  {
    skuId: '0006',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual Instructor-Led 1 day (8 hours) Training (up to 50 students) - Per Class. Holds at least a TOP SECRET-clearance.',
    productId: 'CFQ7TTC0QK1N',
    id: 'CFQ7TTC0QK1N-0006',
  },
  {
    skuId: '0008',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Onsite Instructor-Led 1 day (8 hours) Training (up to 20 students) - Per Class. Holds at least a TOP SECRET-clearance.',
    productId: 'CFQ7TTC0QK1N',
    id: 'CFQ7TTC0QK1N-0008',
  },
  {
    skuId: '0002',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Virtual Subject Matter Expert(s)-Led Advisory Service - 1 day (8 hours). Holds at least a TOP SECRET-clearance.',
    productId: 'CFQ7TTC0QK3B',
    id: 'CFQ7TTC0QK3B-0002',
  },
  {
    skuId: '0002',
    unitOfIssue: 'day (up to 8 hours)',
    title: 'Onsite Subject Matter Expert(s)-Led Advisory Service - 1 day (8 hours). Holds at least a TOP SECRET-clearance.',
    productId: 'CFQ7TTC0QK3C',
    id: 'CFQ7TTC0QK3C-0002',
  },
];

export const sections: SupportServiceSection[] = [
  {
    key: 'alaCarteNoClearance',
    header: 'A La Carte Support - No Clearance Required',
    content: aLaCarteNoClearance,
    deletable: false,
  },
  {
    key: 'tacticalNoClearance',
    header: 'Tactical Edge Support - No Clearance Required',
    content: tacticalNoClearance,
    deletable: false,
  },
  {
    key: 'alaCarteSecret',
    header: 'A La Carte Support - Secret Clearance Required',
    content: aLaCarteSecret,
    deletable: false,
  },
  {
    key: 'tacticalSecret',
    header: 'Tactical Edge Support - Secret Clearance Required',
    content: tacticalSecret,
    deletable: false,
  },
  {
    key: 'alaCartTopSecret',
    header: 'A La Carte Support - Top Secret Clearance Required',
    content: aLaCarteTopSecret,
    deletable: false,
  },
];
