import React, { mergeStyles } from '@fluentui/react';
import { getImages } from 'api/images';
import { FunctionComponent, useEffect, useState } from 'react';
import { DirectoryEscapeCharacter } from 'modules/supportingPages/supportingPages';

export interface ImageRendererProps {
    src: string;
    alt: string;
  }

const imageStyle = mergeStyles({
  maxWidth: '100%',
});

export const ImageRenderer: FunctionComponent<ImageRendererProps> = (props) => {
  const { src, alt } = props;
  const [imageURL, setImageURL] = useState<string | null>(null);

  // Necessary to work with ADO image rendering
  const defaultUrlTransform = (src: string) => {
    const decodedSrc = decodeURIComponent(src).replaceAll('/', DirectoryEscapeCharacter);
    const prefix = `.attachments${DirectoryEscapeCharacter}`;
    const index = decodedSrc.indexOf(prefix);
    if (index !== -1) {
      return decodedSrc.substring(index + prefix.length);
    }
    return decodedSrc;
  };

  useEffect(() => {
    const getImageFile = async (src: string) => {
      const updatedSrc = defaultUrlTransform(src);
      try {
        const fileResponse = await getImages(updatedSrc);

        if (!fileResponse) return '';
        const blob = fileResponse.data;
        const url = await URL.createObjectURL(blob);
        return url;
      } catch (error) {
        return '';
      }
    };

    getImageFile(src).then((url) => setImageURL(url));
  }, [src]);

  return (
    imageURL
      ? <img {...props} alt={alt} src={imageURL} className={imageStyle} />
      : <p>Image not found</p>
  );
};
