import {
  mergeStyles,
  Image,
  ImageFit,
  NeutralColors,
} from '@fluentui/react';
import React, {
  FunctionComponent, useContext,
} from 'react';
import { getExternalPricingCalculatorLink } from 'components/pageNavigation/pageNavigation.functions';
import { ConfigContext } from 'components/configProvider/configContext';
import { RedirectButton } from 'components/button/redirectButton';
import PiggyBank from '../../assets/PiggyBank.png';
import { WalkthroughList } from './walkthroughList';

const wrapperStyles = mergeStyles({
  padding: '0 130px 0',
});

const headerStyles = mergeStyles({
  color: '#242424',
  textAlign: 'center',
  fontFamily: 'Segoe UI',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  paddingBottom: '37.5px',
});

const paragraphStyles = mergeStyles({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#4C4C51',
  paddingBottom: '24px',
});

const cardStyles = mergeStyles({
  padding: '24px 88px 38px 36px',
  minHeight: '340px',
  minWidth: '1100px',
  width: '100%',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)',
  background: NeutralColors.white,
  borderRadius: '4px',
  selectors: {
    ':last-child': {
      marginRight: '0',
    },
  },
});

const rowStyles = mergeStyles({
  display: 'flex',
  textAlign: 'center',
  width: '100%',
});

const getStartedButtonStyles = mergeStyles({
  height: '40px !important',
  width: '177px !important',
  backgroundColor: '#0078D4',
  borderRadius: '4px',
  padding: '8px 16px !important',
  whiteSpace: 'nowrap',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
});

const imageStyles = mergeStyles({
  width: '75px',
  height: '57.222px',
  margin: '0 auto 19px',
});

export const UnderstandingPricingCalculator: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);

  return (
    <section className={wrapperStyles}>

      <div className={rowStyles}>
        <div className={cardStyles}>
          <div className={headerStyles}>
            <Image className={imageStyles} imageFit={ImageFit.contain} src={PiggyBank} alt="Pig adorned with dollar styles standing next to a bar graph" />
            Understanding the Pricing Calculator
          </div>
          <WalkthroughList />
          <div className={paragraphStyles}>
            <RedirectButton text="Create your estimate" styles={getStartedButtonStyles} endpoint={getExternalPricingCalculatorLink(configContext.serverConfig) || ''} openInNewTab />
          </div>
        </div>
      </div>
    </section>
  );
};
