import {
  AccountInfo,
  AuthenticationResult,
  BrowserSystemOptions,
  CacheOptions,
  Configuration,
  EndSessionRequest,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { AzureAdConfig, ServerConfig } from '../../models/serverConfig';
import {
  logDebug,
  logError,
  logInfo,
  logWarning,
} from '../logging/logging';
import { loginCallbackRoute } from '../routes/routes';

let isAcquireTokenSilentRequested = false;

let msalInstance: PublicClientApplication;

let MSAL_CONFIG: Configuration;

let azureAdConfig: AzureAdConfig;

let currentAccount: AccountInfo | null;

let config: ServerConfig;

export const initializeAuth = async (tenantId: string, serverConfig: ServerConfig): Promise<void> => {
  config = serverConfig;
  const cacheOptions: CacheOptions = {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  };

  const browserSystemOptions: BrowserSystemOptions = {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            logError(message, new Error(message));
            return;
          case LogLevel.Verbose:
            logDebug(message);
            return;
          case LogLevel.Warning:
            logWarning(message);
            return;
          default:
            logInfo(message);
        }
      },
    },
  };

  if (tenantId === serverConfig.cacAzureAdAuthConfig.tenantId) {
    MSAL_CONFIG = {
      auth: {
        clientId: serverConfig.cacAzureAdAuthConfig.clientId,
        authority: new URL(`/${serverConfig.cacAzureAdAuthConfig.tenantId}`, serverConfig.cacAzureAdAuthConfig.instance).href,
        redirectUri: loginCallbackRoute(),
        navigateToLoginRequestUrl: false,
      },
      cache: cacheOptions,
      system: browserSystemOptions,
    };
    msalInstance = new PublicClientApplication(MSAL_CONFIG);
    azureAdConfig = serverConfig.cacAzureAdAuthConfig;
  } else if (tenantId === serverConfig?.internalAzureAdAuthConfig?.tenantId) {
    MSAL_CONFIG = {
      auth: {
        clientId: serverConfig.internalAzureAdAuthConfig.clientId,
        authority: new URL(`/${serverConfig.internalAzureAdAuthConfig.tenantId}`, serverConfig.internalAzureAdAuthConfig.instance).href,
        redirectUri: loginCallbackRoute(),
        navigateToLoginRequestUrl: false,
      },
      cache: cacheOptions,
      system: browserSystemOptions,
    };
    msalInstance = new PublicClientApplication(MSAL_CONFIG);
    azureAdConfig = serverConfig.internalAzureAdAuthConfig;
  }
};

export const loadAuthModule = async (): Promise<boolean> => {
  try {
    const resp = await msalInstance.handleRedirectPromise();
    await handleResponse(resp);
    return true;
  } catch (err) {
    logError('An error occurred while attempting to load auth module', err);
    return false;
  }
};

const handleResponse = async (response: AuthenticationResult | null): Promise<void> => {
  if (response !== null) {
    currentAccount = response.account;
    localStorage.setItem('accessToken', response.accessToken);
  } else {
    currentAccount = await getAccount();
  }
};

export const login = async (tenantId?: string): Promise<void> => {
  if (currentAccount !== undefined) {
    await attemptToAcquireTokenSilently(tenantId);
  }

  const loginRedirectRequest = {
    redirectStartPage: window.location.href,
    scopes: [`api://${MSAL_CONFIG.auth.clientId}/default`],
    extraScopesToConsent: [`${config.azureEnvironment.graph}/User.Read`],
  } as RedirectRequest;

  // Request a token for a specific tenant, as opposed to the default server configuration, which is likely
  // "organizations" that tells AAD to use the user's home tenant
  if (tenantId !== undefined) {
    loginRedirectRequest.authority = new URL(`/${tenantId}`, azureAdConfig.instance).href;
  }
  if (!await attemptToAcquireTokenSilently(tenantId)) {
    await msalInstance.acquireTokenRedirect(loginRedirectRequest);
  }
};

const attemptToAcquireTokenSilently = async (tenantId?: string) : Promise<boolean> => {
  if (!isAcquireTokenSilentRequested) {
    isAcquireTokenSilentRequested = true;
    try {
      const account = await getAccount();
      if (account) {
        const silentRequest: SilentRequest = {
          scopes: [`api://${MSAL_CONFIG.auth.clientId}/default`],
          account,
          forceRefresh: false,
        };

        // Request a token for a specific tenant, as opposed to the tenant last used
        if (tenantId !== undefined) {
          silentRequest.authority = new URL(`/${tenantId}`, azureAdConfig.instance).href;
        }
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        if (response) {
          localStorage.setItem('accessToken', response.accessToken);
          isAcquireTokenSilentRequested = false;
          return true;
        }
      }
    } catch (error) {
      logError('An error occurred while attempting to silently acquire an authentication token', error);
      isAcquireTokenSilentRequested = false;
    }
  }

  return false;
};

export const logout = async (): Promise<void> => {
  const logOutRequest: EndSessionRequest = {
    account: currentAccount,
    onRedirectNavigate: () => false,
  };
  localStorage.removeItem('accessToken');
  localStorage.removeItem('activeAuth');
  await msalInstance.logoutRedirect(logOutRequest);
};

export const getAccount = async (): Promise<AccountInfo | null> => {
  if (!msalInstance) {
    return null;
  }

  const currentAccounts = msalInstance.getAllAccounts();

  if (currentAccounts === null || currentAccounts.length < 1) {
    return null;
  }

  if (currentAccounts.length > 1) {
    return currentAccounts[0];
  }

  if (currentAccounts.length === 1) {
    return currentAccounts[0];
  }

  return null;
};

export const getJwtToken = async (): Promise<string | null> => {
  if (await getIdTokenStatus() === TokenStatus.Expired) {
    await login();
  }
  return localStorage.getItem('accessToken');
};

export const checkIfAuthenticated = async (): Promise<boolean> => !(await isIdTokenExpired());

const nowInSeconds = (): number => Math.round(new Date().getTime() / 1000);

/** Gets the id tokens expiration time from the given account in seconds */
const getIdTokenExpiration = async (): Promise<number | null> => {
  const account = await getAccount();
  if (!account) {
    return null;
  }

  const { idTokenClaims } = account;
  const expiry = (idTokenClaims as any)?.exp;
  if (expiry) {
    return parseInt(expiry, 10);
  }
  return null;
};

const isIdTokenExpired = async (): Promise<boolean> => {
  const tokenExpiration = await getIdTokenExpiration();
  if (tokenExpiration == null) {
    return true;
  }

  const currentTimeInSeconds = nowInSeconds();
  if (tokenExpiration <= currentTimeInSeconds) {
    return true;
  }
  await attemptToAcquireTokenSilently();
  return false;
};

export enum TokenStatus {
  Invalid,
  Expired,
  ExpectedValid,
}

const getIdTokenStatus = async (): Promise<TokenStatus> => {
  const tokenExpiration = await getIdTokenExpiration();
  if (tokenExpiration == null) {
    return TokenStatus.Invalid;
  }

  const currentTimeInSeconds = nowInSeconds();
  if (tokenExpiration <= currentTimeInSeconds) {
    return TokenStatus.Expired;
  }
  return TokenStatus.ExpectedValid;
};
