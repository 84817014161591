import React, { FunctionComponent, SetStateAction, useState } from 'react';
import { DefaultButton, mergeStyles } from '@fluentui/react';
import { OrderForm } from 'pages/orderForm/orderPage';
import { SupportServicesOrder } from 'pages/orderForm/supportServices';
import { SaveButton } from '../../components/saving/saveButton';
import { OrderReviewContent } from './orderReviewContent';

const buttonStyle = mergeStyles({
  margin: '20px 0px 20px 20px',
});

const footerButtonsStyle = mergeStyles({
  display: 'flex',
  margin: '0 200px 100px auto',
});

interface OrderReviewProps {
  orderFormFields: OrderForm,
  supportServicesOrder: SupportServicesOrder[],
  onSubmit: () => Promise<void>,
  setIsInReview: React.Dispatch<SetStateAction<boolean>>,
}

export const OrderReview: FunctionComponent<OrderReviewProps> = (props) => {
  const {
    orderFormFields,
    supportServicesOrder,
    onSubmit,
    setIsInReview,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmitClick = async () => {
    setIsSubmitting(true);
    await onSubmit();
    // TODO: show confirmation component.
    setIsSubmitting(false);
  };

  const onBackButtonClick = (): void => {
    setIsInReview(false);
  };

  return (
    <>
      <OrderReviewContent
        order={orderFormFields}
        supportServices={supportServicesOrder}
      />
      <div className={footerButtonsStyle}>
        <DefaultButton
          className={buttonStyle}
          text="Back"
          onClick={onBackButtonClick}
        />
        <SaveButton
          className={buttonStyle}
          defaultText="Submit order"
          saveText="Submitting..."
          onSave={onSubmitClick}
          isSaving={isSubmitting}
        />
      </div>
    </>
  );
};
