import React, { FunctionComponent, useState } from 'react';
import {
  DatePicker, ILabelStyles, ITextFieldStyles, Label, MaskedTextField, mergeStyles, SharedColors, Stack, TextField,
} from '@fluentui/react';
import { getFormattedDate } from '../../modules/datetime/datetime';
import { OrderForm } from './orderPage';

const inputStyles = mergeStyles({
  padding: '55px 90px 0',
});

const wrapperStyles = mergeStyles({
  display: 'flex',
});

const reqiuredMessage = mergeStyles({
  color: SharedColors.red20,
  padding: '10px 0',
});

const stackStyles = mergeStyles({
  width: '100%',
  selectors: {
    ':first-child': {
      marginRight: '38px',
    },
  },
});

const stackItemStyles = mergeStyles({
  marginBottom: '15px',
});

const fieldStyle: Partial<ITextFieldStyles> = {
  subComponentStyles: {
    label: {
      root: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  },
  fieldGroup: {
    selectors: {
      'input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  field: {
    selectors: {
      '&::placeholder': {
        fontStyle: 'normal !important',
      },
    },
  },
};

const labelStyle: Partial<ILabelStyles> = {
  root: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
};

interface OrderFormFieldProps {
  onFieldChange(orderFormFields: Partial<OrderForm>): void,
  showRequiredMessage?: string,
  existingOrderFormFields: OrderForm | undefined,
}

export const OrderFormFields: FunctionComponent<OrderFormFieldProps> = (props) => {
  const { onFieldChange, showRequiredMessage, existingOrderFormFields } = props;
  const [orderFormFields, setOrderFormFields] = useState<Partial<OrderForm>>(existingOrderFormFields as Partial<OrderForm>);
  const onTextChange = (field: string, newValue?: string) => {
    const updatedFields = { ...orderFormFields };
    updatedFields[field as keyof OrderForm] = newValue;
    setOrderFormFields(updatedFields);
    onFieldChange(updatedFields);
  };

  return (
    <div className={inputStyles}>
      {showRequiredMessage && (
        <div className={reqiuredMessage}>{showRequiredMessage}</div>
      )}
      <div className={wrapperStyles}>
        <Stack className={stackStyles}>
          <Stack.Item className={stackItemStyles}>
            <TextField
              id="clarity_agencyOffice"
              styles={fieldStyle}
              label="Agency/office name"
              placeholder="Enter a name"
              value={existingOrderFormFields?.agencyName || ''}
              onChange={(event: any, newValue?: string) => onTextChange('agencyName', newValue)}
              required
            />
          </Stack.Item>
          <Stack.Item className={stackItemStyles}>
            <TextField
              id="clarity_taskOrderNumber"
              styles={fieldStyle}
              label="Task order number"
              placeholder="Enter a task order number"
              value={existingOrderFormFields?.taskOrderNumber || ''}
              onChange={(event: any, newValue?: string) => onTextChange('taskOrderNumber', newValue)}
              required
            />
          </Stack.Item>
          <Stack.Item className={stackItemStyles}>
            <TextField
              id="clarity_clinNumber"
              styles={fieldStyle}
              label="Task order CLIN number"
              placeholder="Enter a task order CLIN number"
              value={existingOrderFormFields?.taskOrderClin || ''}
              onChange={(event: any, newValue?: string) => onTextChange('taskOrderClin', newValue)}
              required
            />
          </Stack.Item>
          <Stack.Item className={stackItemStyles}>
            <Label styles={labelStyle} required>
              Start date
            </Label>
            <DatePicker
              id="clarity_startDate"
              placeholder="mm/dd/yyyy"
              ariaLabel="Enter a start date"
              textField={{ styles: fieldStyle }}
              value={existingOrderFormFields?.startDate ? new Date(existingOrderFormFields?.startDate) : undefined}
              onSelectDate={(date: Date | null | undefined) => onTextChange('startDate', getFormattedDate(date))}
              formatDate={getFormattedDate}
            />
          </Stack.Item>
        </Stack>
        <Stack className={stackStyles}>
          <Stack.Item className={stackItemStyles}>
            <TextField
              id="clarity_pocName"
              styles={fieldStyle}
              label="Point of contact (POC) name"
              placeholder="Enter a POC name"
              value={existingOrderFormFields?.pointOfContactName || ''}
              onChange={(event: any, newValue?: string) => onTextChange('pointOfContactName', newValue)}
              required
            />
          </Stack.Item>
          <Stack.Item className={stackItemStyles}>
            <MaskedTextField
              id="clarity_pocNumber"
              styles={fieldStyle}
              label="POC phone number"
              placeholder="Enter a POC phone number"
              value={existingOrderFormFields?.pointOfContactPhoneNumber || ''}
              mask="(999) 999 - 9999"
              onChange={(event: any, newValue?: string) => onTextChange('pointOfContactPhoneNumber', newValue)}
              required
            />
          </Stack.Item>
          <Stack.Item className={stackItemStyles}>
            <TextField
              id="clarity_pocEmail"
              styles={fieldStyle}
              label="POC email"
              placeholder="Enter a POC email address"
              value={existingOrderFormFields?.pointOfContactEmail || ''}
              onChange={(event: any, newValue?: string) => onTextChange('pointOfContactEmail', newValue)}
              required
            />
          </Stack.Item>
          <Stack.Item className={stackItemStyles}>
            <TextField
              id="clarity_location"
              styles={fieldStyle}
              label="Location (onsite) - if applicable"
              placeholder="Enter an onsite location"
              value={existingOrderFormFields?.onSiteLocation || ''}
              onChange={(event: any, newValue?: string) => onTextChange('onSiteLocation', newValue)}
            />
          </Stack.Item>
        </Stack>
      </div>
    </div>
  );
};
