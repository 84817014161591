import {
  DefaultButton,
  IContextualMenuItem,
  IContextualMenuProps,
  Image,
  ImageFit,
  Link,
  mergeStyles,
} from '@fluentui/react';
import React, {
  FunctionComponent, useContext,
} from 'react';
import { openLinkInNewTab } from 'modules/links/links';
import { ConfigContext } from '../../components/configProvider/configContext';
import logo from '../../assets/logo.svg';
import { landingRoute } from '../../modules/routes/routes';

const topNavBarStyles = mergeStyles({
  padding: '0.25em 2em',
  display: 'flex',
  fontSize: '1em',
  alignItems: 'center',
  flex: 1,
  fontFamily: 'Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif',
});

const menuStyles = mergeStyles({
  color: '#1A1A1F !important',
  border: '1px solid transparent',
  padding: '1em 5px',
  fontSize: '13px',
  selectors: {
    ':hover': {
      backgroundColor: 'transparent',
      border: '1px solid transparent',
    },
    ':active': {
      backgroundColor: 'transparent',
      border: '1px solid transparent',
    },
    ':focus': {
      backgroundColor: 'transparent',
      border: '1px solid transparent',
    },
    '&.is-expanded': {
      backgroundColor: 'transparent',
      border: '1px solid transparent',
    },
  },
});

let logoStyles = mergeStyles({
  height: '25.25px',
  cursor: 'pointer',
});

let azureStyles = mergeStyles({
  cursor: 'pointer',
  paddingRight: '20px',
  height: '16px',
  fontSize: '18px',
  lineHeight: 0,
  paddingLeft: '24px',
  fontWeight: '600',
  textUnderlineOffset: '1px',
  selectors: {
    ':focus': {
      textDecoration: 'none',
    },
  },
});

const buttonStyle = {
  label: {
    fontWeight: '400',
  },
  labelHovered: {
    textDecoration: 'underline',
  },
};

const linkStyle = {
  root: {
    color: '#1A1A1F',
  },
  rootHovered: {
    color: '#1A1A1F',
  },
};

export const GlobalNavBar: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  const isAgc = configContext.serverConfig?.isAgc;
  const getMenuProps = (menuItems: IContextualMenuItem[]): IContextualMenuProps => ({
    shouldFocusOnMount: true,
    items: menuItems,
  });

  const mockedMenuItems: IContextualMenuItem[] = [
  ];

  // Created method because default DefaultButton got a bit long.
  const buttonToOpenLinkInSameTab = (text: string, menuProps: IContextualMenuProps, endpoint: string | undefined, isHidden?: boolean) => {
    let localLinkStyles = menuStyles;
    if (isHidden) {
      localLinkStyles = mergeStyles(menuStyles, {
        display: 'none',
      });
    }

    return (
      <DefaultButton
        disabled={isHidden}
        className={localLinkStyles}
        styles={buttonStyle}
        text={text}
        menuProps={menuProps}
        onMenuClick={() => openLinkInNewTab(endpoint || '')}
      />
    );
  };

  const onHeaderItemClick = (endpoint?: string) => {
    if (isAgc) {
      return;
    }
    openLinkInNewTab(endpoint || '');
  };

  if (isAgc) {
    const defaultCursorStyle = {
      cursor: 'default',
    };

    azureStyles = mergeStyles(azureStyles, defaultCursorStyle);
    logoStyles = mergeStyles(logoStyles, defaultCursorStyle);
  }

  return (
    <div className={topNavBarStyles}>
      <Image className={logoStyles} imageFit={ImageFit.contain} src={logo} alt="Microsoft Logo" onClick={() => onHeaderItemClick(configContext.serverConfig?.microsoftEndpoint)} />
      <Link disabled={isAgc} className={azureStyles} styles={linkStyle} onClick={() => onHeaderItemClick(configContext.serverConfig?.azureEndpoint || '')}>Azure</Link>
      {buttonToOpenLinkInSameTab('JWCC', getMenuProps(mockedMenuItems), landingRoute(), false)}
      {buttonToOpenLinkInSameTab('Explore', getMenuProps(mockedMenuItems), configContext.serverConfig?.exploreEndpoint, isAgc)}
      {buttonToOpenLinkInSameTab('Products', getMenuProps(mockedMenuItems), configContext.serverConfig?.productsEndpoint, false)}
      {buttonToOpenLinkInSameTab('Solutions', getMenuProps(mockedMenuItems), configContext.serverConfig?.solutionsEndpoint, isAgc)}
      {buttonToOpenLinkInSameTab('Pricing', getMenuProps(mockedMenuItems), configContext.serverConfig?.pricingEndpoint, false)}
      {buttonToOpenLinkInSameTab('Partners', getMenuProps(mockedMenuItems), configContext.serverConfig?.partnersEndpoint, isAgc)}
      {buttonToOpenLinkInSameTab('Resources', getMenuProps(mockedMenuItems), configContext.serverConfig?.resourcesEndpoint, false)}
    </div>
  );
};
