import {
  IImageStyleProps,
  IImageStyles,
  Image,
  ImageFit,
  IStyleFunctionOrObject,
  mergeStyles,
  NeutralColors,
} from '@fluentui/react';
import React, {
  FunctionComponent,
} from 'react';
import banner from '../../assets/banner.png';

const wrapperStyles = mergeStyles({
  position: 'relative',
  color: NeutralColors.white,
  fontFamily: 'Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif',
});

const imageStyles = mergeStyles({
  minWidth: '1200px',
});

export interface BannerProps {
  image?: string,
  children?: JSX.Element | JSX.Element[],
  imageStyles?: IStyleFunctionOrObject<IImageStyleProps, IImageStyles>,
}

export const Banner: FunctionComponent<BannerProps> = (props: BannerProps) => (
  <div className={wrapperStyles}>
    <Image className={imageStyles} styles={props.imageStyles} imageFit={ImageFit.contain} src={props.image ? props.image : banner} alt="Silhouetted, sunrise view of three soldiers in tactical gear and helmets walking past a grounded helicopter in the background" />
    {props.children}
  </div>
);
