import React, { FunctionComponent, useState } from 'react';
import { mergeStyles, NeutralColors } from '@fluentui/react';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { ConfirmationPage } from 'pages/confirmation/confirmation';
import { PageHeader } from 'components/pageHeader/pageHeader';
import { Banner } from '../../components/banner/banner';
import { Footer } from '../../components/footer/footer';
import { ContactUsFormFields } from './contactUsFormFields';
import { createContactUsForm } from '../../api/contactUs';
import { ContactUsPostCommand } from '../../generated/clientApi';
import {
  isEmailValid, isPhoneNumberValid, isRequiredTextArrayValid, isRequiredTextInputValid, removeNonNumberCharacters,
} from '../../modules/validation/validation';

const pageStyles = mergeStyles({
  backgroundColor: '#F5F5F5',
});

const textWrapperStyles = mergeStyles({
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  paddingTop: '100px',
  position: 'absolute',
  marginLeft: '324px',
  width: 'fit-content',
  '@media ((min-width: 1470px) and (max-width: 1650px))': {
    paddingTop: '70px',
  },
  '@media (max-width: 1469px)': {
    paddingTop: '50px',
  },
});

const headerStyles = mergeStyles({
  fontSize: '32px',
  fontWeight: '600',
  lineHeight: '32px',
  marginBottom: '32px',
});

const subHeaderStyles = mergeStyles({
  fontSize: '16px',
  fontWeight: '600',
});

const descriptionStyles = mergeStyles({
  fontSize: '16px',
  fontWeight: '400',
});

const formWrapperStyles = mergeStyles({
  minWidth: '900px',
  margin: '-130px 0 0 324px',
  paddingBottom: '40px',
  width: '60%',
  position: 'relative',
  top: 0,
  left: 0,
  backgroundColor: NeutralColors.white,
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
});

export interface ContactUsForm {
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string,
  role: string,
  governmentAffiliation: string,
  customerStatus: string,
  cloudUsage: string[],
  useCase: string[],
  projectDescription: string,
  projectClassifications: string[],
}

export const ContactUsPage: FunctionComponent = () => {
  const [contactUsFormFields, setContactUsFormFields] = useState<ContactUsForm>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formValidMessage, setFormValidMessage] = useState<string | undefined>();
  const [successfullySubmitted, setSuccessfullySubmitted] = useState<boolean>(false);

  const onFieldChange = (orderForm: ContactUsForm) => {
    setContactUsFormFields(orderForm);
  };

  const validateForm = (): string | undefined => {
    if (!isRequiredTextInputValid(contactUsFormFields?.firstName)
      || !isRequiredTextInputValid(contactUsFormFields?.lastName)
      || !isRequiredTextInputValid(contactUsFormFields?.phoneNumber)
      || !isRequiredTextInputValid(contactUsFormFields?.emailAddress)
      || !isRequiredTextInputValid(contactUsFormFields?.role)
      || !isRequiredTextInputValid(contactUsFormFields?.governmentAffiliation)
      || !isRequiredTextArrayValid(contactUsFormFields?.projectClassifications)
      || !isRequiredTextInputValid(contactUsFormFields?.customerStatus)) {
      return '* All required fields must be filled out before submitting.';
    }

    if (!isEmailValid(contactUsFormFields?.emailAddress)) {
      return '* Email is an invalid format';
    }

    if (!isPhoneNumberValid(contactUsFormFields?.phoneNumber)) {
      return '* Phone number must be a valid 10-digit US phone number';
    }

    return undefined;
  };

  const onSubmit = async () => {
    const validationMessage = validateForm();
    setFormValidMessage(validationMessage);
    if (validationMessage) {
      return;
    }

    setIsSubmitting(true);
    const contactUsForm = new ContactUsPostCommand({
      firstName: contactUsFormFields?.firstName.trim() || '',
      lastName: contactUsFormFields?.lastName.trim() || '',
      phoneNumber: removeNonNumberCharacters(contactUsFormFields?.phoneNumber || ''),
      emailAddress: contactUsFormFields?.emailAddress.trim() || '',
      role: contactUsFormFields?.role.trim() || '',
      governmentAffiliation: contactUsFormFields?.governmentAffiliation.trim() || '',
      projectClassifications: contactUsFormFields?.projectClassifications || [],
      customerStatus: contactUsFormFields?.customerStatus.trim() || '',
      projectDescription: contactUsFormFields?.projectDescription?.trim() || '',
      cloudUsage: contactUsFormFields?.cloudUsage || [],
      useCase: contactUsFormFields?.useCase || [],
    });
    try {
      await createContactUsForm(contactUsForm);
      showSuccess('Contact us successfully sent');
    } catch (error) {
      showError('There was a problem submitting your contact request. Try again or contact support.', 10, true);
    }

    // TODO: Send MEO email
    setIsSubmitting(false);
    setSuccessfullySubmitted(true);
  };

  return (
    (successfullySubmitted ? <ConfirmationPage headerText="Microsoft Joint Warfighting Cloud Capability" message="Thank you, we will reach out soon!" /> : (
      <div className={pageStyles}>
        <Banner>
          <div className={textWrapperStyles}>
            <PageHeader title="Microsoft Joint Warfighting Cloud Capability" headerStyles={headerStyles} />
            <div className={subHeaderStyles}>
              DO NOT ENTER CLASSIFIED INFORMATION IN THIS FORM
            </div>
            <div className={descriptionStyles}>
              Complete the form to connect with a Microsoft team member supporting the Joint Warfighting Cloud Capability (JWCC) contract.
            </div>
          </div>
        </Banner>
        <div className={formWrapperStyles}>
          <ContactUsFormFields onFieldChange={onFieldChange} showRequiredMessage={formValidMessage} onSubmit={onSubmit} isSubmitting={isSubmitting} />
        </div>
        <Footer />
      </div>
    )
    ));
};
