import { SupportingPages } from 'components/supportingPages/supportingPages';
import React from 'react';
import { useParams } from 'react-router-dom';
import { mergeStyles } from '@fluentui/react';
import { UserGuideBanner } from './userGuideBanner';

const pageWrapper = mergeStyles({
  position: 'absolute',
  width: '100%',
});

export const PricingCalculatorUserGuide: React.FunctionComponent = () => {
  const { subpath } = (useParams() as { subpath: string }); // Used for storing the initial sub path of the documentation.

  return (
    <div className={pageWrapper}>
      <UserGuideBanner />
      <SupportingPages defaultPath={subpath} />
    </div>

  );
};
