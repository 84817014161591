import {
  mergeStyles,
} from '@fluentui/react';
import React, {
  FunctionComponent,
} from 'react';
import { RouteProps } from 'react-router-dom';

const wrapperStyles = mergeStyles({
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const Scrollable: FunctionComponent<RouteProps> = ({ children }) => (
  <div id="scrollable" className={wrapperStyles}>{children}</div>
);
