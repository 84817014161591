/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { mergeStyles } from '@fluentui/react';
import { getDocumentation } from 'api/documentation';
import remarkGfm from 'remark-gfm';
import { TableRenderer } from './tableRenderer';
import { ImageRenderer } from './imageRenderer';

export interface PageContentProps {
  currentPath: string;
}

const mainContentStyle = mergeStyles({
  backgroundColor: 'white',
});

const paragraphStyles = mergeStyles({
  display: 'block',
  lineHeight: '24px',
  fontWeight: '400',
  fontSize: '16px',
  color: '#424242',
  paddingBottom: '24px',
  maxWidth: '860px',
});

export const PageContent: FunctionComponent<PageContentProps> = (props) => {
  const { currentPath } = props;
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    async function requestMarkdownFile() {
      const response = await getDocumentation(decodeURIComponent(currentPath));

      if (!response) {
        setMarkdownContent('Sorry, the requested page was not found.');
      } else {
        const data = response;
        blobToString(data.data).then((result) => {
          setMarkdownContent(result);
        }).catch((_error) => {
          setMarkdownContent('Sorry, the requested page was not found.');
        });
      }
    }

    if (currentPath) {
      requestMarkdownFile();
    }
  }, [currentPath]);

  const blobToString = (blob: Blob) : Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsText(blob);
  });

  return (
    <div className={mainContentStyle}>
      <div className={paragraphStyles}>
        <ReactMarkdown
          plugins={[remarkGfm]}
          renderers={{
            image: (props) => <ImageRenderer {...props} />,
            table: (props) => <TableRenderer {...props} />,
          }}
        >
          {markdownContent}
        </ReactMarkdown>
      </div>
    </div>
  );
};
