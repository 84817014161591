import React, {
  FunctionComponent,
} from 'react';
import { ConfigContext, ConfigContextProps } from './configContext';
import { ServerConfig } from '../../models/serverConfig';

interface ConfigProviderProps {
  serverConfig?: ServerConfig,
  children?: JSX.Element,
}

export const ConfigProvider: FunctionComponent<ConfigProviderProps> = (props) => {
  const { children, serverConfig } = props;

  function loadClarity(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = '(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "gguckht37l");';
    document.head.appendChild(script);
  }

  if (serverConfig && serverConfig.useCacAuth && !serverConfig.useInternalAuth && !serverConfig.isAgc) {
    loadClarity();
  }

  const contextProps: ConfigContextProps = {
    serverConfig,
  };

  return (<ConfigContext.Provider value={contextProps}>{children}</ConfigContext.Provider>);
};
