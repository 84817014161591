import {
  Image,
  ImageFit,
  mergeStyles,
  Stack,
} from '@fluentui/react';
import React, {
  FunctionComponent,
} from 'react';
import { PageNavigationAnchorNames } from '../../components/pageNavigation/pageNavigation';
import overview from '../../assets/overview.png';
import { OverviewCard } from './overviewCard';

const wrapperStyles = mergeStyles({
  padding: '60px 130px 32px 160px',
});

const imageSectionStyles = mergeStyles({
  display: 'flex',
  paddingBottom: '75px',
});

const imageStyles = mergeStyles({
  height: '402px',
  minWidth: '270px',
  paddingRight: '41px',
});

const overviewStyles = mergeStyles({
  minWidth: '370px',
});

const blueTitleStyles = mergeStyles({
  display: 'block',
  lineHeight: '32px',
  fontWeight: '600',
  fontSize: '24px',
  color: '#0078D4',
  letterSpacing: '0.1em',
  paddingBottom: '18px',
});

const titleStyles = mergeStyles({
  display: 'block',
  lineHeight: '32px',
  fontWeight: '600',
  fontSize: '24px',
  paddingBottom: '18px',
  textAlign: 'center',
});

const subtitleStyles = mergeStyles({
  display: 'block',
  lineHeight: '24px',
  fontWeight: '600',
  fontSize: '20px',
  color: '#4C4C51',
  paddingBottom: '14px',
});

const paragraphStyles = mergeStyles({
  display: 'block',
  lineHeight: '24px',
  fontWeight: '400',
  fontSize: '16px',
  color: '#424242',
  paddingBottom: '24px',
  maxWidth: '860px',
});

const cardSectionStyles = mergeStyles({
  display: 'flex',
});

export const Overview: FunctionComponent = () => (
  <section id={PageNavigationAnchorNames.Overview} className={wrapperStyles}>
    <div className={imageSectionStyles}>
      <Image className={imageStyles} imageFit={ImageFit.contain} src={overview} alt="Overview Image" />
      <Stack className={overviewStyles}>
        <Stack.Item className={blueTitleStyles}>
          Overview
        </Stack.Item>
        <Stack.Item className={subtitleStyles}>
          What is JWCC cloud services?
        </Stack.Item>
        <Stack.Item className={paragraphStyles}>
          Joint Warfighting Cloud Capability (JWCC) is an enterprise-led acquisition vehicle that makes Microsoft Azure directly available to all of Department of Defense (DoD) at every classification level – Unclassified, Secret, and Top Secret.
        </Stack.Item>
        <Stack.Item className={paragraphStyles}>
          With JWCC, Microsoft will support DoD in achieving its goal of an enterprise-level tactical cloud through our robust catalog of cloud services, secure tactical edge devices, advanced AI capabilities, and continuous commercial innovation.
        </Stack.Item>
        <br />
        <Stack.Item className={subtitleStyles}>
          Our commitment
        </Stack.Item>
        <Stack.Item className={paragraphStyles}>
          Along with JWCC, Microsoft is supporting key DoD priorities including AI and cloud strategies and Joint All-Domain Command and Control (JADC2) initiatives – further affirming our unwavering commitment of more than 45 years to provide the U.S. military with the best technology we make.
        </Stack.Item>
      </Stack>
    </div>
    <div>
      <div className={titleStyles}>
        Microsoft brings together
      </div>
      <div className={cardSectionStyles}>
        <OverviewCard firstLine="Over" secondLine="165K miles" thirdLine="of fiber and" fourthLine="subsea cable" />
        <OverviewCard firstLine="Over" secondLine="160" thirdLine="intelligent" fourthLine="edge sights" />
        <OverviewCard firstLine="Over" secondLine="8 trillion" thirdLine="signals per day with" fourthLine="massive signal" fifthLine="depth and diversity" />
        <OverviewCard firstLine="Cutting-edge" secondLine="AI" thirdLine="and" fourthLine="machine learning" />
        <OverviewCard firstLine="A global team of" secondLine="8500+" thirdLine="security experts to" fourthLine="deliver unparalleled" fifthLine="protection" />
        <OverviewCard firstLine="Investments of" secondLine="$20 billion" thirdLine="over the next 5 years" />
      </div>
    </div>
  </section>
);
