import React, {
  FunctionComponent, useContext, useEffect, useState,
} from 'react';
import { AuthContext } from '../../components/authProvider/authContext';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { AccessDenied } from '../accessDenied/accessDenied';

export const LoginCallBack: FunctionComponent = () => {
  const authContext : any = useContext(AuthContext);
  const [isLoginError, setLoginError] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!await authContext.signinRedirectCallback()) {
        setLoginError(true);
      }
    })();
  }, [authContext]);

  return !isLoginError ? <CenteredProgressDots /> : <AccessDenied />;
};
