import React, { FunctionComponent } from 'react';
import {
  Image, ImageFit, mergeStyles, NeutralColors,
} from '@fluentui/react';
import { PageHeader } from 'components/pageHeader/pageHeader';
import { Banner } from '../../components/banner/banner';
import { Footer } from '../../components/footer/footer';
import confirmation from '../../assets/confirmation.png';

const pageStyles = mergeStyles({
  backgroundColor: '#F5F5F5',
  height: 'calc(100vh - 42px)',
  display: 'flex',
  flexDirection: 'column',
});

const textWrapperStyles = mergeStyles({
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  position: 'absolute',
  paddingTop: '130px',
  marginLeft: '324px',
  width: 'fit-content',
});

const headerStyles = mergeStyles({
  fontSize: '32px',
  fontWeight: '600',
  lineHeight: '32px',
  marginBottom: '10px',
});

const formWrapperStyles = mergeStyles({
  minWidth: '900px',
  margin: '-130px 0 0 324px',
  paddingBottom: '40px',
  width: '60%',
  position: 'relative',
  top: 0,
  left: 0,
  backgroundColor: NeutralColors.white,
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
});

const messageStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '32px',
  lineHeight: '32px',
  textAlign: 'center',
  margin: '50px 0 85px',
});

const imageStyles = mergeStyles({
  height: '150px',
  marginTop: '85px',
});

export interface ConfirmationProps {
  headerText: string,
  message: string,
}

export const ConfirmationPage: FunctionComponent<ConfirmationProps> = (props) => {
  const { headerText, message } = props;

  return (
    <div className={pageStyles}>
      <Banner>
        <div className={textWrapperStyles}>
          <PageHeader title={headerText} headerStyles={headerStyles} />
        </div>
      </Banner>
      <div className={formWrapperStyles}>
        <Image className={imageStyles} imageFit={ImageFit.center} src={confirmation} />
        <div className={messageStyles}>
          {message}
        </div>
      </div>
      <Footer />
    </div>
  );
};
