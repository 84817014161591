import React, { FunctionComponent } from 'react';
import { RouteProps } from 'react-router-dom';
import {
  checkIfAuthenticated,
  getAccount, loadAuthModule, login, logout,
} from '../../modules/auth/auth';
import { landingRoute } from '../../modules/routes/routes';
import { AuthContext } from './authContext';

const redirectUri = 'redirectUri';
export const AuthProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const contextProps = {
    signin: async (tenantId: string | undefined) => login(tenantId),

    signinRedirectCallback: async (): Promise<boolean> => {
      if (await loadAuthModule()) {
        window.location.replace(sessionStorage.getItem(redirectUri) || landingRoute());
        return true;
      }

      return false;
    },

    signout: async () => {
      const logoutTask = async () => {
        await logout();
        sessionStorage.clear();
        window.location.replace(landingRoute());
      };
      logoutTask();
    },

    isAuthenticated: async () => checkIfAuthenticated(),

    getUser: async () => getAccount(),
  };

  return (
    <AuthContext.Provider value={contextProps}>{children}</AuthContext.Provider>
  );
};
