import React, { FunctionComponent, useEffect, useState } from 'react';
import { getDocumentNavigation } from 'api/documentNavigation';
import {
  Nav, INavLink, INavStyles, INavLinkGroup, Icon, IComponentAs, INavButtonProps,
} from '@fluentui/react';
import { Link, useHistory } from 'react-router-dom';
import { defaultPricingCalculatorRoute } from 'modules/routes/routes';
import { DirectoryEscapeCharacter } from 'modules/supportingPages/supportingPages';

export interface PageNavigatorProps {
  currentPath: string,
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>
}

export interface DocumentNavigationNode {
  name: string;
  fileName: string;
  children: DocumentNavigationNode[];
}

const navStyles: Partial<INavStyles> = {
  root: {
    minWidth: '100px',
    minHeight: '430px',
    boxSizing: 'border-box',
    overflowY: 'auto',
    marginRight: '80px',
    borderRight: '1px solid #EDEBE9',
  },
};

export const PageNavigator: FunctionComponent<PageNavigatorProps> = (props) => {
  const { currentPath, setCurrentPath } = props;
  const [pages, setPages] = useState<DocumentNavigationNode[] | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    const parseFileNames = (fileNames: string[] | undefined): DocumentNavigationNode[] => {
      if (!fileNames) {
        return [];
      }

      const documentNavigationNodes: DocumentNavigationNode[] = [];

      fileNames.forEach((filePath) => {
        const path = filePath.split(DirectoryEscapeCharacter);
        let currentChildren = documentNavigationNodes;

        path.forEach((part) => {
          let existingChild = currentChildren.find((child) => child.name === part);

          if (!existingChild) {
            const newChild: DocumentNavigationNode = {
              name: part,
              fileName: filePath,
              children: [],
            };

            currentChildren.push(newChild);
            existingChild = newChild;
          }

          currentChildren = existingChild.children;
        });
      });

      return documentNavigationNodes;
    };

    async function requestDocumentNavigation() {
      const response = await getDocumentNavigation();
      setPages(parseFileNames(response));
    }

    requestDocumentNavigation();
  }, []);

  useEffect(() => {
    if (pages && pages.length > 0 && !currentPath) {
      setCurrentPath(pages[0].fileName!);
    }
  }, [pages, setCurrentPath, currentPath]);

  const getRedirect = (path: string) => decodeURIComponent(path);

  const getNavLinks = (items: DocumentNavigationNode[]): INavLink[] => (
    items.map((item) => {
      const { fileName, children } = item;
      const name = item.name.replace('.md', '');
      const url = children ? getRedirect(fileName) : '';
      const expandAriaLabel = `Expand ${name} section`;
      const links = children ? getNavLinks(children) : undefined;
      const key = fileName;
      const isHeader = !children;
      const target = '_blank';
      const isExpanded = true;

      // Return the INavLink object
      return {
        name, url, expandAriaLabel, links, key, target, isHeader, isExpanded,
      };
    })
  );

  const getNavLinkGroups = (items: DocumentNavigationNode[]): INavLinkGroup[] => {
    const navLinkGroup: INavLinkGroup = {
      links: getNavLinks(items),
    };
    return [navLinkGroup];
  };

  const handleLinkClick = (ev?: React.MouseEvent<HTMLElement>, href?: string) => {
    if (href && ev) {
      ev.preventDefault();
      history.push(`${defaultPricingCalculatorRoute()}user-guide/${href}`);
      setCurrentPath(href);
    }
  };

  const onRenderLink = (props: INavButtonProps) => (
    <Link
      className={props.className}
      style={{ color: 'inherit', boxSizing: 'border-box' }}
      to={props.href || '/'}
      onClick={(event) => {
        if (props.href === '/') {
          event.preventDefault();
        } else {
          handleLinkClick(event, props.href);
        }
      }}
    >
      <span style={{ display: 'flex' }}>
        {!!props.iconProps && (
          <Icon style={{ margin: '0 4px' }} {...props.iconProps} />
        )}
        {props.children}
      </span>
    </Link>
  );

  return (
    <Nav
      selectedKey={currentPath}
      ariaLabel="Supporting Documentation Navigation Menu"
      styles={navStyles}
      groups={getNavLinkGroups(pages || [])}
      linkAs={onRenderLink as IComponentAs<INavButtonProps>}
    />
  );
};
