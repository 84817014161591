import {
  Link,
  mergeStyles,
  Stack,
} from '@fluentui/react';
import { HowToCard } from 'components/howToCard/howToCard';
import { openLinkInNewTab } from 'modules/links/links';
import React, {
  FunctionComponent, useContext,
} from 'react';
import { ConfigContext } from '../../components/configProvider/configContext';
import { PageNavigationAnchorNames } from '../pageNavigation/pageNavigation';

const wrapperStyles = mergeStyles({
  padding: '60px 130px 0',
});

const howToStyles = mergeStyles({
  paddingBottom: '60px',
});

const nowWhatStyles = mergeStyles({
  paddingBottom: '60px',
});

const titleStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '40px',
  color: '#4C4C51',
  paddingBottom: '32px',
  textAlign: 'center',
  scrollMargin: '3em',
});

const paragraphStyles = mergeStyles({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#4C4C51',
  paddingBottom: '24px',
});

const nowWhatCardStyles = mergeStyles({
  marginRight: '40px',
  padding: '15px 22px',
  minHeight: '340px',
  width: '33%',
  maxWidth: '500px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  selectors: {
    ':last-child': {
      marginRight: '0',
    },
  },
});

const rowStyles = mergeStyles({
  display: 'flex',
});

const linkStyles = mergeStyles({
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '600',
  selectors: {
    ':hover': {
      textDecoration: 'underline',
    },
    ':focus': {
      textDecoration: 'none',
    },
  },
});

const linkStyle = {
  root: {
    color: '#0078D4',
  },
};

export const GettingStarted: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);

  const isAgc = configContext.serverConfig?.isAgc;
  const learnMoreText = !isAgc ? 'Learn more' : 'Learn more in the public portal';
  const cloudServiceText = !isAgc ? 'For unclassified cloud services' : 'For classified cloud services';
  const azureGovernmentPortalText = !isAgc ? 'Azure Government Portal' : 'Azure Government Secret Portal';
  const macText = !isAgc ? 'MAC commercial' : 'MAC Secret Portal';
  return (
    <section className={wrapperStyles}>
      <div className={howToStyles}>
        <div id={PageNavigationAnchorNames.GettingStarted} className={titleStyles}>
          Getting started with JWCC Cloud Services
        </div>
        <Stack>
          <Stack.Item className={rowStyles}>
            <HowToCard cardNumber="1" text="Talk to your Microsoft account team about your requirement and if JWCC best fits your needs." link={configContext.serverConfig?.contactUsEndpoint || ''} linkText="Contact us" />
            <HowToCard cardNumber="2" text="Determine if due to government requirements and contractor’s unique qualifications a justification & approval (J&A) is required." />
            <HowToCard cardNumber="3" text="Through the HaCC customer portal, complete the task order package process." link={configContext.serverConfig?.communityEndpoint || ''} linkText={learnMoreText} />
          </Stack.Item>
          <Stack.Item className={rowStyles}>
            <HowToCard cardNumber="4" text="Release the request for proposal (RFP) to applicable JWCC service providers." />
            <HowToCard cardNumber="5" text="Receive service provider proposals and evaluate based on the criteria established." />
            <HowToCard cardNumber="6" text="Fund awarded task order and start receiving cloud-based services." />
          </Stack.Item>
        </Stack>
      </div>

      <div className={nowWhatStyles}>
        <div className={titleStyles}>
          What do I do now that I have a task order?
        </div>
        <div className={rowStyles}>
          <div className={nowWhatCardStyles}>
            <div className={paragraphStyles}>
              After you have an awarded task order and have obtained your Azure JWCC tenant/account through ATAT, most cloud services and our Cloud Support Base Packages can be purchased and deployed through the main Azure Portal on each respective cloud fabric.
            </div>
            <div className={paragraphStyles}>
              {`${cloudServiceText}, JWCC customers can access through:`}
              <ul>
                <li hidden={isAgc}>
                  <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.azurePortalPublicEndpoint || '')}>Azure Portal commercial</Link>
                  &nbsp;
                  (up to IL2 workloads)
                </li>
                <li>
                  <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.azurePortalGovernmentEndpoint || '')}>{azureGovernmentPortalText}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={nowWhatCardStyles}>
            <div className={paragraphStyles}>
              All seat-based licenses available for purchase through the JWCC contract (currently Azure Active Directory Premium P1/P2 and Azure Information Protection Premium P1/P2) can be purchased, assigned, and managed through the Microsoft Admin Center (MAC) on each respective cloud fabric.
            </div>
            <div className={paragraphStyles}>
              {`${cloudServiceText}, JWCC customers can access through:`}
              <ul>
                <li>
                  <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.macPublicEndpoint || '')}>{macText}</Link>
                  &nbsp;
                  (including GCC)
                </li>
                <li hidden={isAgc}>
                  <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.macGovernmentEndpoint || '')}>MAC Government</Link>
                  &nbsp;
                  (GCC-H)
                </li>
              </ul>
            </div>
          </div>
          <div className={nowWhatCardStyles}>
            <div className={paragraphStyles}>
              {`For situations in which the DoD may require additional and/or specific targeted items not in the portals, customers can order through the ${isAgc ? 'public ' : ''}`}
              <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.orderFormEndpoint || '')}>JWCC order form</Link>
              .
            </div>
            <div className={paragraphStyles}>
              This order form includes A La Carte Cloud Support services that augment the base support packages to include, targeted support in training, documentation, and/or advisory assistance services.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
