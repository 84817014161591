import {
  mergeStyles,
  Image,
  ImageFit,
  Link,
} from '@fluentui/react';
import React, {
  FunctionComponent,
} from 'react';
import { useHistory } from 'react-router-dom';
import Lock from '../../assets/Lock.png';

const wrapperStyles = mergeStyles({
  padding: '60px 130px 0',
});

const nowWhatStyles = mergeStyles({
  paddingBottom: '60px',
  display: 'flex',
  flexDirection: 'column',
});

const titleStyles = mergeStyles({
  minWidth: '700px',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: 'normal',
  color: '#242424',
  paddingBottom: '19px',
  textAlign: 'center',
  scrollMargin: '3em',
});

const paragraphHeaderStyles = mergeStyles({
  color: '#242424',
  fontFamily: 'Segoe UI',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '22px',
  paddingBottom: '14px',
});

const paragraphStyles = mergeStyles({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 'normal',
  color: '#242424',
  paddingBottom: '24px',
});

const nowWhatCardStyles = mergeStyles({
  marginRight: '12px',
  padding: '17px 61px',
  minHeight: '128px',
  width: '33%',
  maxWidth: '500px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  background: '#FFF',
  selectors: {
    ':last-child': {
      marginRight: '0',
    },
  },
});

const rowStyles = mergeStyles({
  display: 'flex',
  margin: '0 auto',
});

const imageStyles = mergeStyles({
  width: '75px',
  height: '57.222px',
  margin: 'auto',
});

export const CalculatorBenefits: FunctionComponent = () => {
  const history = useHistory();
  return (
    <section className={wrapperStyles}>
      <div className={nowWhatStyles}>
        <div className={titleStyles}>
          <Image className={imageStyles} imageFit={ImageFit.contain} src={Lock} alt="A single lock with a light bulb next to it" />
          Features and benefits of the Pricing Calculator
        </div>
        <div className={rowStyles}>
          <div className={nowWhatCardStyles}>
            <div className={paragraphHeaderStyles}>
              Understand your calculator data
            </div>
            <div className={paragraphStyles}>
              See where pricing comes from, how calculations work, and alternative sources for the prices in the calculator.
            </div>
            <Link onClick={() => history.push('/pricing-calculator/user-guide/Understand%20the%20Azure%20pricing%20calculator&&Understand%20calculator%20data.md')}>Understand the data</Link>
          </div>
          <div className={nowWhatCardStyles}>
            <div className={paragraphHeaderStyles}>
              View estimates with your agreement prices
            </div>
            <div className={paragraphStyles}>
              See your negotiated prices to get a more accurate representation of your expected costs.
            </div>
            <Link onClick={() => history.push('/pricing-calculator/user-guide/Understand%20the%20Azure%20pricing%20calculator&&Build%20an%20estimate.md')}>Build and view your estimate</Link>
          </div>
          <div className={nowWhatCardStyles}>
            <div className={paragraphHeaderStyles}>
              Use advanced features
            </div>
            <div className={paragraphStyles}>
              Save, share, export and clone your estimates to makes it easier to plan and budget for your usage
            </div>
            <Link onClick={() => history.push('/pricing-calculator/user-guide/Understand%20the%20Azure%20pricing%20calculator&&Use%20advanced%20calculator%20features.md')}>Customize using advanced features</Link>
          </div>
        </div>
      </div>
    </section>
  );
};
