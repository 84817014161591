import {
  IButtonStyles,
  mergeStyles, PrimaryButton, Spinner, SpinnerSize,
} from '@fluentui/react';
import React from 'react';

const spinnerStyle = mergeStyles({
  marginRight: '5px',
  paddingBottom: '2px',
});

interface SaveButtonProps {
  id?: string,
  disabled?: boolean;
  title?: string;
  isSaving?: boolean;
  saveText?: string;
  defaultText: string;
  className?: string;
  styles?: Partial<IButtonStyles>;
  onSave(): void;
}

export const SaveButton: React.FunctionComponent<SaveButtonProps> = (props) => {
  SaveButton.defaultProps = {
    disabled: false,
    title: '',
    isSaving: false,
    saveText: 'Saving...',
    className: '',
  };

  const {
    id, disabled, title, isSaving, onSave, className, styles,
  } = props;
  const { saveText, defaultText } = props;

  return (
    <PrimaryButton id={id} disabled={disabled || isSaving} title={title} className={className} styles={styles} onClick={onSave} data-testid="save">
      {isSaving ? (
        <>
          <Spinner className={spinnerStyle} size={SpinnerSize.small} />
          {saveText}
        </>
      ) : (
        <>{defaultText}</>
      )}
    </PrimaryButton>
  );
};
