import {
  mergeStyles,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import React, {
  FunctionComponent, useContext,
} from 'react';
import { ConfigContext } from 'components/configProvider/configContext';
import { AuthBar } from '../authBar/authBar';
import { GlobalNavBar } from '../globalNavigation/globalNavigation';

const topBarStyles = mergeStyles({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '1.2em',
  color: NeutralColors.black,
});

const topBannerStyles = mergeStyles({
  backgroundColor: NeutralColors.white,
  padding: '0 1em',
  display: 'flex',
});

export const TopNavBar: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  return (
    <div className={topBarStyles}>
      <div className={topBannerStyles}>
        <GlobalNavBar />
        {configContext.serverConfig?.isAgc ? <></> : <AuthBar />}
      </div>
    </div>
  );
};
