import { PrimaryButton } from '@fluentui/react';
import { openLinkInNewTab } from 'modules/links/links';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

export interface RedirectButtonProps {
    text: string;
    styles: string;
    endpoint: string;
    iconName?: string;
    openInNewTab?: boolean;
}

export const RedirectButton: FunctionComponent<RedirectButtonProps> = ({
  text, styles, endpoint, iconName, openInNewTab,
}) => {
  const history = useHistory();

  const goToLink = (link: string) => {
    if (openInNewTab) {
      openLinkInNewTab(link);
      return;
    }

    history.push(link);
  };

  return (
    <PrimaryButton
      text={text}
      onClick={() => goToLink(endpoint || '')}
      className={styles}
      iconProps={{ iconName: iconName || '' }}
    />
  );
};
