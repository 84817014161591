import { DocumentationGetClient, FileResponse } from '../generated/clientApi';
import { getConfig } from '../modules/config/config';

export const getDocumentation = async (encodedPath : string | undefined): Promise<FileResponse | null> => {
  const client = new DocumentationGetClient(getConfig().apiBaseUri);
  let result = null;
  if (encodedPath) {
    result = await client.get(encodedPath);
  } else {
    result = await client.get('');
  }
  return result;
};
