import { AccountInfo } from '@azure/msal-browser';
import {
  Icon, mergeStyles,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import React, {
  FunctionComponent, useCallback, useContext, useEffect, useState,
} from 'react';
import { AuthContext } from '../authProvider/authContext';

const authBarStyle = mergeStyles({
  display: 'flex',
  margin: 'auto 0 auto auto',
});

const navLinkStyles = mergeStyles({
  color: NeutralColors.black,
  textDecoration: 'none',
  margin: 'auto 0',
});

const userStyles = mergeStyles({
  display: 'flex',
});

const iconStyles = mergeStyles({
  margin: 'auto 0',
});

const userInfoStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto 2em auto 1em',
});

const subheadingStyles = mergeStyles({
  color: NeutralColors.black,
});

export const AuthBar: FunctionComponent = () => {
  const authContext = useContext(AuthContext);
  const [user, setUser] = useState<AccountInfo | null>(null);
  const [isUserAuthenticated, setAuthenticationStatus] = useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      const usr = await authContext.getUser();
      setUser(usr);
    };
    const checkAuthenticated = async () => {
      const authenticated = await authContext.isAuthenticated();
      setAuthenticationStatus(authenticated);
    };

    fetchUser();
    checkAuthenticated();
  }, [authContext]);

  const handleLogin = useCallback(() => {
    authContext.signin();
  }, [authContext]);

  const userData = (
    <div id="clarity_userData" className={userStyles}>
      <Icon className={iconStyles} iconName="AddFriend" />
      <div className={userInfoStyles}>
        <span>{user?.name}</span>
        <span className={subheadingStyles}>{user?.username}</span>
      </div>
    </div>
  );

  const handleLogout = useCallback(() => {
    const signOutTask = async () => {
      setAuthenticationStatus(false);
      await authContext.signout();
    };
    signOutTask();
  }, [authContext]);

  return (
    <div className={authBarStyle}>
      {isUserAuthenticated && userData}
      <a href="#" className={navLinkStyles} onClick={isUserAuthenticated ? handleLogout : handleLogin}>
        {isUserAuthenticated ? <>Logout</> : <>Login</>}
      </a>
    </div>
  );
};
