import React, { FunctionComponent } from 'react';
import {
  mergeStyles,
  NeutralColors,
  IIconStyles,
  ActionButton,
} from '@fluentui/react';

const wrapperStyles = mergeStyles({
  display: 'block',
});

const headerStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '28px',
  color: '#4C4C51',
  paddingBottom: '24px',
});

const buttonStyles = mergeStyles({
  display: 'flex',
  width: '271px',
  height: '56px',
  alignItems: 'flex-start',
  borderRadius: 'var(--Medium, 4px)',
  border: `1px solid ${NeutralColors.gray30}`,
  background: 'NeutralBackground1.Rest',
  fontSize: '16px',
  minHeight: 'unset',
  margin: '6px 0',
  selectors: {
    flexContainer: {
      height: '40px',
    },
    textContainer: {
      display: 'flex',
      margin: 'auto 0',
    },
    label: {
    },
  },
});

const iconStyles: IIconStyles = {
  root: {
    fontSize: '20px',
    padding: '0 10px',
    color: '#242424 !important',
    marginRight: '8px',
  },
};

const onClickMailtoHandler = () => {
  const emailAddress = 'mailto:jwcc_pmo@microsoft.com';
  window.location.href = emailAddress;
};

export const AdditionalSupport: FunctionComponent = () => (
  <div className={wrapperStyles}>
    <div className={headerStyles}>
      Additional Support
    </div>

    <ActionButton
      text="Contact us"
      className={buttonStyles}
      iconProps={{
        iconName: 'Mail',
        styles: iconStyles,
      }}
      onClick={onClickMailtoHandler}
    />
    <ActionButton
      text="Download PDF"
      className={buttonStyles}
      iconProps={{
        iconName: 'Download',
        styles: iconStyles,
      }}
      disabled
    />
    <ActionButton
      text="Provide feedback"
      className={buttonStyles}
      iconProps={{
        iconName: 'Feedback',
        styles: iconStyles,
      }}
      disabled
    />
  </div>
);
