import React, { FunctionComponent } from 'react';
import { mergeStyles } from '@fluentui/react';

export interface PageHeaderProps {
    title: string,
    headerStyles: string,
}

const ignoreHeaderStyles = mergeStyles({
  all: 'unset',
});

export const PageHeader: FunctionComponent<PageHeaderProps> = (props) => {
  const {
    title,
    headerStyles,
  } = props;

  return (
    <header className={headerStyles}>
      <h1 className={ignoreHeaderStyles}>
        {title}
      </h1>
    </header>
  );
};
