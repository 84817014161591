import React from 'react';
import { Link, mergeStyles } from '@fluentui/react';
import { UnderstandingPricingCalculator } from 'components/documentationHero/understandingPricingCalculator';
import { CalculatorBenefits } from 'components/documentationHero/calculatorBenefits';
import { RedirectButton } from 'components/button/redirectButton';
import { useHistory } from 'react-router-dom';
import { Footer } from '../../components/footer/footer';
import { PricingCalculatorBanner } from './pricingCalculatorBanner';

const pageWrapper = mergeStyles({
  position: 'absolute',
  width: '100%',
});

const backgroundStyles = mergeStyles({
  background: '#F5F5F5',
});

const buttonStyles = mergeStyles({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  paddingBottom: '10px',
  marginRight: '130px',
});

const returnButtonStyles = mergeStyles({
  display: 'inline-flex',
  margin: '20px 130px 30px',
  gap: '4px',
  color: '#000',
  border: '1px solid #D1D1D1 !important',
  backgroundColor: '#FFF',
  borderRadius: 'var(--Medium, 4px);',
  minWidth: '150px',
  selectors: {
    ':hover': {
      backgroundColor: 'NeutralBackground1.Rest',
      textDecoration: 'underline',
      color: '#000',
    },
    ':active': {
      color: '#000',
      backgroundColor: 'NeutralBackground1.Rest',
      textDecoration: 'none',
    },
  },
});

export const PricingCalculator: React.FunctionComponent = () => {
  const history = useHistory();

  return (
    <div className={pageWrapper}>
      <PricingCalculatorBanner />

      <div className={backgroundStyles}>
        <RedirectButton
          text="Back to JWCC"
          styles={returnButtonStyles}
          endpoint="/"
          iconName="ReturnKey"
        />
        <div className={buttonStyles}>
          <Link onClick={() => history.push('/pricing-calculator/user-guide')}>View calculator user guide</Link>
        </div>
        <UnderstandingPricingCalculator />
        <CalculatorBenefits />
      </div>
      <Footer />
    </div>
  );
};
