import {
  mergeStyles, PrimaryButton,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { ServerConfig } from 'models/serverConfig';
import React, {
  Dispatch,
  FunctionComponent, SetStateAction,
} from 'react';

const topBarStyles = mergeStyles({
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Segoe UI',
  fontSize: '1.2em',
  color: NeutralColors.black,
});

const tenantSelectionButtonStyles = mergeStyles({
  margin: '5px',
});

const loginSelectionButtonStyles = mergeStyles({
  width: '100%',
  height: '30%',
  justifyContent: 'right',
  alignItems: 'right',
  display: 'flex',
  margin: 'auto 0 auto auto',
});

export interface LoginSelectionProps {
  setActiveAuthTenant: Dispatch<SetStateAction<string | null>>,
  serverConfig?: ServerConfig
}

interface AuthEnvironmentTenants {
  cacAuthTenantId?: string,
  internalAuthTenantId?: string,
}

export const LoginSelection: FunctionComponent<LoginSelectionProps> = (props) => {
  const { serverConfig } = props;
  const authTenantOptions: AuthEnvironmentTenants = { cacAuthTenantId: serverConfig?.cacAzureAdAuthConfig.tenantId, internalAuthTenantId: serverConfig?.internalAzureAdAuthConfig?.tenantId };

  const getLoginButtonForTenant = (authTenant: string, loginLinkText: string) => (
    <PrimaryButton
      className={tenantSelectionButtonStyles}
      onClick={
      () => {
        localStorage.setItem('activeAuth', authTenant);
        props.setActiveAuthTenant(authTenant);
      }
    }
    >
      {loginLinkText}
    </PrimaryButton>
  );

  return (
    <div className={topBarStyles}>
      <div className={loginSelectionButtonStyles}>
        <div>Dev/Test Only: Select which account type to login with: </div>
        {authTenantOptions?.internalAuthTenantId && getLoginButtonForTenant(authTenantOptions?.internalAuthTenantId, 'Use with @microsoft.com Account')}
        {authTenantOptions?.cacAuthTenantId && getLoginButtonForTenant(authTenantOptions.cacAuthTenantId, 'Use with CAC')}
      </div>
    </div>
  );
};
