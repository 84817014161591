import React, { FunctionComponent, useContext, useState } from 'react';
import { mergeStyles, NeutralColors } from '@fluentui/react';
import { createOrder } from 'api/orders';
import { OrderPostCommand, SupportService } from 'generated/clientApi';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { OrderReview } from 'pages/orderReview/orderReview';
import { ConfigContext } from 'components/configProvider/configContext';
import { PageHeader } from 'components/pageHeader/pageHeader';
import { Banner } from '../../components/banner/banner';
import { Footer } from '../../components/footer/footer';
import { SupportServicesOrder } from './supportServices';
import { OrderFormInputs } from './orderFormInputs';
import {
  isEmailValid, isPhoneNumberValid, isRequiredTextInputValid, removeNonNumberCharacters,
} from '../../modules/validation/validation';
import { ConfirmationPage } from '../confirmation/confirmation';

const pageStyles = mergeStyles({
  backgroundColor: '#F5F5F5',
});

const textWrapperStyles = mergeStyles({
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  position: 'absolute',
  paddingTop: '130px',
  marginLeft: '324px',
  width: 'fit-content',
});

const headerStyles = mergeStyles({
  fontSize: '32px',
  fontWeight: '600',
  lineHeight: '32px',
  marginBottom: '10px',
});

const subHeaderStyles = mergeStyles({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '32px',
});

const formWrapperStyles = mergeStyles({
  minWidth: '900px',
  margin: '-130px 0 0 324px',
  paddingBottom: '40px',
  width: '60%',
  position: 'relative',
  top: 0,
  left: 0,
  backgroundColor: NeutralColors.white,
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
});

export interface OrderForm {
  agencyName: string,
  taskOrderNumber: string,
  taskOrderClin: string,
  startDate: string,
  pointOfContactName: string,
  pointOfContactPhoneNumber: string,
  pointOfContactEmail: string,
  onSiteLocation?: string,
}

export const OrderPage: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  const [orderFormFields, setOrderFormFields] = useState<OrderForm>();
  const [supportServicesOrder, setSupportServicesOrder] = useState<SupportServicesOrder[]>([]);
  const [isInReview, setIsInReview] = useState<boolean>(false);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState<boolean>(false);

  const onFieldChange = (orderForm: OrderForm) => {
    setOrderFormFields(orderForm);
  };

  const onQuantityChanged = (servicesOrder: SupportServicesOrder[]) => {
    setSupportServicesOrder(servicesOrder);
  };

  const validateForm = (): string | undefined => {
    if (!isRequiredTextInputValid(orderFormFields?.agencyName)
      || !isRequiredTextInputValid(orderFormFields?.pointOfContactName)
      || !isRequiredTextInputValid(orderFormFields?.pointOfContactPhoneNumber)
      || !isRequiredTextInputValid(orderFormFields?.pointOfContactEmail)
      || !isRequiredTextInputValid(orderFormFields?.taskOrderNumber)
      || !isRequiredTextInputValid(orderFormFields?.taskOrderClin)
      || !isRequiredTextInputValid(orderFormFields?.startDate)) {
      return '* All required fields must be filled out before submitting.';
    }

    if (!isEmailValid(orderFormFields?.pointOfContactEmail)) {
      return '* Email is an invalid format';
    }

    if (!isPhoneNumberValid(orderFormFields?.pointOfContactPhoneNumber)) {
      return '* Phone number must be a valid 10-digit US phone number';
    }

    return undefined;
  };

  const onSubmit = async () => {
    const order = new OrderPostCommand({
      agencyName: orderFormFields?.agencyName.trim() || '',
      pointOfContactName: orderFormFields?.pointOfContactName.trim() || '',
      pointOfContactPhoneNumber: removeNonNumberCharacters(orderFormFields?.pointOfContactPhoneNumber || ''),
      pointOfContactEmail: orderFormFields?.pointOfContactEmail.trim() || '',
      onSiteLocation: orderFormFields?.onSiteLocation?.trim() || '',
      taskOrderNumber: orderFormFields?.taskOrderNumber.trim() || '',
      taskOrderClin: orderFormFields?.taskOrderClin.trim() || '',
      startDate: new Date(Date.parse(orderFormFields?.startDate || '')),
      // We need to explicitly map every support service or we will get an error "toJson is not a function"
      // See NSwag issue here: https://github.com/RicoSuter/NSwag/issues/2862
      supportServices: supportServicesOrder?.filter((order) => order.quantity).map((order) => (
        new SupportService({
          id: order.productId,
          sku: order.skuId,
          quantity: order.quantity || 0,
          description: order.description,
          unit: order.unit,
        }))),
    });
    try {
      await createOrder(order as OrderPostCommand);
      showSuccess('Order successfully posted');

      setSuccessfullySubmitted(true);
    } catch (error) {
      showError('There was a problem submitting your order. Try again or contact support.', 10, true);
    }
  };

  const orderPageElement = () => (
    successfullySubmitted ? (
      <ConfirmationPage headerText="Microsoft JWCC Support Services Order Form" message="Thank you, we have received your order!" />
    ) : (
      <div className={pageStyles}>
        <Banner>
          <div className={textWrapperStyles}>
            <PageHeader title="Microsoft JWCC Support Services Order Form" headerStyles={headerStyles} />
            <div className={subHeaderStyles}>
              {isInReview ? 'Please review your order below' : 'Complete the form to order support offerings for the Joint Warfighting Cloud Capability (JWCC) contract.'}
            </div>
          </div>
        </Banner>
        <div className={formWrapperStyles}>
          {isInReview && orderFormFields && supportServicesOrder ? (
            <OrderReview
              orderFormFields={orderFormFields}
              supportServicesOrder={supportServicesOrder}
              onSubmit={onSubmit}
              setIsInReview={setIsInReview}
            />
          ) : (
            <OrderFormInputs
              onFieldChange={onFieldChange}
              onQuantityChanged={onQuantityChanged}
              validateForm={validateForm}
              setIsInReview={setIsInReview}
              orderFormFields={orderFormFields}
              supportServicesOrder={supportServicesOrder}
            />
          )}
        </div>
        <Footer />
      </div>
    ));

  return !configContext.serverConfig?.isAgc ? orderPageElement() : (<></>);
};
