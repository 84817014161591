import { mergeStyles, IImageStyles } from '@fluentui/react';
import { Banner } from 'components/banner/banner';
import React from 'react';
import { PageHeader } from 'components/pageHeader/pageHeader';
import userGuideBanner from '../../assets/userGuideBanner.png';

const imageStyles: Partial<IImageStyles> = {
  image: {
    minHeight: '220px',
    minWidth: '1800px',
  },
};

const headersFlexStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  gridRowGap: '10px',
  top: '30%',
  left: '7%',
  position: 'absolute',
  zIndex: 1,
});

const headerStyles = mergeStyles({
  fontSize: '32px',
  fontWeight: '600',
  lineHeight: '48px',
});

const subheaderTextStyles = mergeStyles({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '20px',
});

export const UserGuideBanner: React.FunctionComponent = () => (
  <Banner image={userGuideBanner} imageStyles={imageStyles}>
    <div className={headersFlexStyle}>
      <PageHeader title="Microsoft JWCC Support" headerStyles={headerStyles} />
      <div className={subheaderTextStyles}>
        Find answers with JWCC user guide
      </div>
    </div>
  </Banner>
);
