import {
  Icon, IStyle, mergeStyles, mergeStyleSets,
} from '@fluentui/react';
import * as React from 'react';
import { useCallback, KeyboardEvent, useMemo } from 'react';
import { Accordion, IAccordionType } from '../accordion/accordion';

/**
 * Usage Example:
 *

  interface ItemType extends IAccordionType {
    content: string,
    header: string,
  }

  export const ExamplePage: FunctionComponent = () => {
    const [listItems, setListItems] = useState<ItemType[]>([
      {
        content: 'Content 1',
        header: 'Header 1',
        key: 'Test1',
        deletable: false,
      },
      {
        content: 'Content 2',
        header: 'Header 2',
        key: 'Test2',
        deletable: true,
      },
    ]);

    const onRenderItem = useCallback((item: ItemType) => (
      <div>
        {item.content}
      </div>
    ), []);

    const onRenderHeader = useCallback((item: ItemType) => (
      <div>
        {item.header}
      </div>
    ), []);

    const onItemsChange = useCallback((updatedItems: ItemType[]) => {
      setListItems([...updatedItems]);
    }, [setListItems]);

    const onItemAdd = () => {
      setListItems((items: ItemType[]) => { // TODO: Using the length/index as the key will not work as users add/remove items
        items.push({
          content: `Content ${items.length + 1}`,
          header: `Header ${items.length + 1}`,
          key: `Test ${items.length + 1}`,
          deletable: true,
        });
        return [...items];
      });
    };

    return (
      <ExpandableList items={listItems} onRenderItem={onRenderItem} onRenderHeader={onRenderHeader} onItemsChange={onItemsChange} onItemAdd={onItemAdd} />
    );
  };

 */

export interface IExpandableListStyle {
  root?: IStyle;
  accordion?: IStyle;
  accordionHeader?: IStyle;
}

export interface ExpandableListProps<T> {
  disableClickActions?: boolean,
  items: T[],
  onRenderHeader: (item: T, index: number) => JSX.Element | JSX.Element[],
  onRenderItem: (item: T) => JSX.Element | JSX.Element[],
  onItemsChange?: (items: T[]) => any,
  onItemAdd?: () => any,
  addItemText?: string,
  styles?: IExpandableListStyle,
  className?: string,
  defaultIsExpanded?: boolean,
  isExpanded?: boolean,
  isReadOnly?: boolean,
}

const addRowStyle = mergeStyles({
  borderBottom: '1px solid #eee',
  padding: '10px 0',
  margin: '0 10px',
  display: 'flex',
  outline: 'none',
  cursor: 'pointer',
});

const addRowIconStyle = mergeStyles({
  margin: 'auto 0',
});

const addRowTextStyle = mergeStyles({
  margin: 'auto 10px',
});

const headerStyle = mergeStyles({
  display: 'flex',
  flexGrow: 1,
});

const headerIconStyle = mergeStyles({
  margin: 'auto 10px',
});

export const ExpandableList = <T extends IAccordionType>(props: ExpandableListProps<T>): JSX.Element => {
  const {
    items,
    disableClickActions,
    onRenderHeader,
    onRenderItem,
    onItemsChange,
    onItemAdd,
    addItemText,
    styles,
    className,
    defaultIsExpanded,
    isExpanded,
    isReadOnly,
  } = props;

  const classNames = useMemo(() => mergeStyleSets({
    root: [
      className,
      styles?.root,
    ],
    accordion: [
      styles?.accordion,
    ],
  }), [className, styles?.root, styles?.accordion]);

  const accordionStyles = useMemo(() => ({ header: styles?.accordionHeader }), [styles?.accordionHeader]);

  const onDeleteRowCallback = useCallback((event) => {
    if (onItemsChange === undefined) {
      return;
    }
    if (disableClickActions) {
      return;
    }
    const itemKey = event.target.id;
    const itemIndex = items.findIndex((item) => item.key === itemKey);
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
      onItemsChange(items);
    }
  }, [onItemsChange, items, disableClickActions]);

  const addRowClick = useCallback(() => {
    if (onItemAdd === undefined) {
      return;
    }
    if (disableClickActions) {
      return;
    }
    onItemAdd();
  }, [onItemAdd, disableClickActions]);

  const addRowKeyUp = useCallback((event: KeyboardEvent) => {
    if (onItemAdd === undefined) {
      return;
    }
    if (event.keyCode === 32 || event.keyCode === 13) { // key code of space and enter, respectively
      onItemAdd();
    }
  }, [onItemAdd]);

  const headerIconDisabledColorStyle = {
    color: '#808080',
  };

  const addRowIconDisabledColorStyle = {
    color: '#808080',
  };

  const addRowIconColorStyle = {
    color: '#2b9000',
  };

  return (
    <div className={classNames.root}>
      {items.map((item: T, index: number) => {
        const header = (
          <div className={headerStyle}>
            {onRenderHeader(item, index)}
            {item.deletable && <Icon className={headerIconStyle} style={disableClickActions ? headerIconDisabledColorStyle : undefined} role="img" id={item.key} aria-label="Delete Section" iconName="Delete" onClick={onDeleteRowCallback} />}
          </div>
        );

        return (
          <Accordion styles={accordionStyles} ariaLabel={item.header} key={item.key} defaultIsExpanded={defaultIsExpanded} headerElement={header} className={classNames.accordion} isExpanded={isExpanded} isReadOnly={isReadOnly}>
            {onRenderItem(item)}
          </Accordion>
        );
      })}
      {addItemText && (
        <div className={addRowStyle} role="button" tabIndex={0} aria-label="Add Expandable Row" onClick={addRowClick} onKeyUp={addRowKeyUp}>
          <Icon className={addRowIconStyle} style={disableClickActions ? addRowIconDisabledColorStyle : addRowIconColorStyle} iconName="Add" />
          <div className={addRowTextStyle}>
            {`Add another ${addItemText}`}
          </div>
        </div>
      )}
    </div>
  );
};
