import { mergeStyles, IImageStyles } from '@fluentui/react';
import { Banner } from 'components/banner/banner';
import { RedirectButton } from 'components/button/redirectButton';
import { ConfigContext } from 'components/configProvider/configContext';
import { PageHeader } from 'components/pageHeader/pageHeader';
import { getExternalPricingCalculatorLink } from 'components/pageNavigation/pageNavigation.functions';
import React, { useContext } from 'react';
import pricingBanner from '../../assets/pricingBanner.svg';

const textWrapperStyles = mergeStyles({
  zIndex: 1,
  top: 0,
  position: 'absolute',
  padding: '25px 0 47px 124px',
  minWidth: '1000px',
  height: '150px',
});

const headerStyles = mergeStyles({
  fontSize: '32px',
  fontWeight: '600',
  lineHeight: '48px',
});

const subHeaderStyles = mergeStyles({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '30px',
});

const calculatorButtonStyles = mergeStyles({
  borderRadius: '4px',
  backgroundColor: '#97C137',
  border: '1px solid #97C137 !important',
  color: '#050505',
  fontSize: '16px',
  fontWeight: '600',
  height: 'fit-content !important',
  lineHeight: '22px',
  padding: '8px 16px !important',
  margin: '20px 0 auto 0',
  selectors: {
    ':hover': {
      backgroundColor: '#97C137',
      textDecoration: 'underline',
      color: '#323130',
    },
    ':active': {
      color: '#323130',
      backgroundColor: '#97C137',
      textDecoration: 'none',
    },
    '.ms-Button-flexContainer': {
      height: 'fit-content',
    },
  },
});

const imageStyles: Partial<IImageStyles> = {
  image: {
    minHeight: '220px',
    minWidth: '1800px',
  },
};

export const PricingCalculatorBanner: React.FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  const externalPricingCalculatorEndpoint = getExternalPricingCalculatorLink(configContext.serverConfig) || '';

  return (
    <Banner image={pricingBanner} imageStyles={imageStyles}>
      <div className={textWrapperStyles}>
        <PageHeader title="JWCC Pricing calculator" headerStyles={headerStyles} />
        <div className={subHeaderStyles}>
          Estimate the cost for your Azure solution. Access the pricing calculator to plan and budget for your Azure usage.
        </div>
        <RedirectButton
          text="Pricing calculator"
          styles={calculatorButtonStyles}
          endpoint={externalPricingCalculatorEndpoint || ''}
          openInNewTab
        />
      </div>
    </Banner>
  );
};
