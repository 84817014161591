import { initializeIcons } from '@fluentui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';

initializeIcons('/fonts/');

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
