import React, { FunctionComponent, SetStateAction, useState } from 'react';
import { DefaultButton, mergeStyles } from '@fluentui/react';
import { OrderFormFields } from './orderFormFields';
import { SupportServices, SupportServicesOrder } from './supportServices';
import { SaveButton } from '../../components/saving/saveButton';
import { landingRoute } from '../../modules/routes/routes';
import { OrderForm } from './orderPage';

const buttonStyle = mergeStyles({
  margin: '20px 0px 20px 20px',
});

const footerButtonsStyle = mergeStyles({
  display: 'flex',
  margin: '0 200px 100px auto',
});

export interface OrderFormProps {
  orderFormFields: OrderForm | undefined,
  supportServicesOrder: SupportServicesOrder[],
  onFieldChange: (orderForm: OrderForm) => void,
  onQuantityChanged: (servicesOrder: SupportServicesOrder[]) => void,
  validateForm: () => string | undefined,
  setIsInReview: React.Dispatch<SetStateAction<boolean>>,
}

export const OrderFormInputs: FunctionComponent<OrderFormProps> = (props) => {
  const {
    orderFormFields,
    supportServicesOrder,
    onFieldChange,
    onQuantityChanged,
    validateForm,
    setIsInReview,
  } = props;
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [formValidMessage, setFormValidMessage] = useState<string | undefined>();

  const onValidate = async () => {
    setIsValidating(true);
    const validationMessage = validateForm();
    setFormValidMessage(validationMessage);
    if (!validationMessage) {
      setIsInReview(true);
    } else {
      setIsInReview(false);
    }
    setIsValidating(false);
  };

  return (
    <>
      <OrderFormFields onFieldChange={onFieldChange} showRequiredMessage={formValidMessage} existingOrderFormFields={orderFormFields} />
      <SupportServices onQuantityChanged={onQuantityChanged} supportServicesOrder={supportServicesOrder} />
      <div className={footerButtonsStyle}>
        <DefaultButton
          className={buttonStyle}
          text="Cancel"
          href={landingRoute()}
        />
        <SaveButton
          className={buttonStyle}
          defaultText="Review order"
          saveText="Validating order..."
          onSave={onValidate}
          isSaving={isValidating}
        />
      </div>
    </>
  );
};
