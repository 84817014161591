import {
  Image,
  ImageFit,
  mergeStyles,
} from '@fluentui/react';
import React, {
  FunctionComponent,
} from 'react';
import threeSteps from '../../assets/threeSteps.svg';

const threeStepsStyle = mergeStyles({
  marginBottom: '50px',
  marginLeft: '0',
  minWidth: '1000px',
});

export const WalkthroughList: FunctionComponent = () => (
  <Image className={threeStepsStyle} imageFit={ImageFit.contain} src={threeSteps} alt="Three Steps" />
);
