import {
  mergeStyles,
} from '@fluentui/react';
import React, { FunctionComponent } from 'react';

interface SkipToMainProps {
    id?: string,
  }

const defaultID = 'default-skip-content-id';

const skipToMainInvisibleStyles = mergeStyles({
  position: 'absolute',
  left: '-1000px',
});

// dynamically updated with ref hince eslint complains
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const skipToMainVisibleStyles = mergeStyles({
  position: 'static',
  width: '100%',
});

const skipToContentAnchorTag = React.createRef<HTMLAnchorElement>();

const handleFocus : React.FocusEventHandler<HTMLAnchorElement> = () => {
  if (skipToContentAnchorTag.current) {
    skipToContentAnchorTag.current.className = 'skipToMainVisibleStyles';
  }
};

// place to show invisible skip to main link which will be visible on focus
export const SkipToMain: FunctionComponent<SkipToMainProps> = (props) => (<a href={`#${props.id ? props.id : defaultID}`} className={skipToMainInvisibleStyles} ref={skipToContentAnchorTag} onFocus={handleFocus}>Skip to main</a>);

// place where the main content starts
export const SkipNavContent: FunctionComponent<SkipToMainProps> = (props) => (<div id={props.id ? props.id : defaultID} />);
