import {
  Announced, DetailsList, DetailsListLayoutMode, IColumn, IDetailsListCheckboxProps, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowProps, IGroup, ISelection, IStyleFunctionOrObject, mergeStyles, SelectionMode,
} from '@fluentui/react';
import React, {
  useEffect, useState,
} from 'react';

export interface ListRow {
    id: string,
}

export interface ListColumn extends IColumn {
    isSortingDisabled?: boolean,
    isFilterable?: boolean,
    getFilterValue?(item?: any): string,
    filterName?: string,
    getFieldValue?(item: any): string,
  }

export interface ListGroup extends IGroup {
   isDropEnabled : false,
}

export interface ListProps {
    items: ListRow[] | undefined,
    columns: ListColumn[],
    groups: ListGroup[] | undefined,
    onRenderRow?(onRenderRowProps: IDetailsRowProps | undefined, defaultRender: any): any,
    onRenderItemColumn?(item?: any, index?: number | undefined, column?: ListColumn | undefined): any,
    onRenderHeader?(headerProps: any, defaultRender: any): any,
    styles?: IStyleFunctionOrObject<IDetailsListStyleProps, IDetailsListStyles>,
    initialSortFieldName?: string,
    initialSortDescending?: boolean,
    notScrollable?: boolean,
    selectionMode?: SelectionMode,
    isCompactMode: boolean,
    selection?: ISelection,
    onRenderCheckBox?(onRenderRowProps: IDetailsListCheckboxProps | undefined, defaultRender: any): any,
  }

const getCellText = (item: any, column: ListColumn): string => {
  let value = '';
  if (item && column && column.getFieldValue) {
    value = column.getFieldValue(item);
  } else if (item && column && column.fieldName) {
    value = item[column.fieldName];
  }

  return value;
};

const BasicListStyles = mergeStyles({
  paddingBottom: '1em',
  wordWrap: 'break-word',
});

const scrollableStyle = mergeStyles({
  height: 'auto',
  position: 'relative',
  backgroundColor: 'white',
  overflowY: 'auto',
});

const notScrollableStyle = mergeStyles({});

export const GroupedList: React.FunctionComponent<ListProps> = (props) => {
  const [columns, setColumns] = useState(props.columns.map(setAllColumnDefaults));
  const [items, setItems] = useState(props.items);
  const [announcedMessage, setAnnouncedMessage] = useState('');

  function setColumnDefaults(propColumn: ListColumn) {
    const column = { ...propColumn };
    column.minWidth = column.minWidth ?? 210;
    column.maxWidth = Math.max(column.minWidth, column.maxWidth ?? 350);
    column.isRowHeader = column.isRowHeader != null ? column.isRowHeader : true;
    column.isResizable = column.isResizable != null ? column.isResizable : true;
    column.isSortingDisabled = column.isSortingDisabled != null ? column.isSortingDisabled : false;
    column.isSorted = column.isSorted != null ? column.isSorted : false;
    column.isSortedDescending = column.isSortedDescending != null ? column.isSortedDescending : false;
    column.isPadded = column.isPadded != null ? column.isPadded : false;
    column.sortAscendingAriaLabel = column.sortAscendingAriaLabel || 'Sorted A to Z';
    column.sortDescendingAriaLabel = column.sortDescendingAriaLabel || 'Sorted Z to A';
    column.data = column.data || 'string';
    return column;
  }

  function setColumnOnClick(aColumn: ListColumn) {
    const column = { ...aColumn };
    const existingColumnClick = props.columns.find((col) => col.key === column.key)?.onColumnClick;
    column.onColumnClick = function onClick(ev: React.MouseEvent<HTMLElement>, clickedColumn: ListColumn) {
      onColumnClick.call(ev, clickedColumn);
      existingColumnClick?.call(this, ev, clickedColumn); // eslint-disable-line no-unused-expressions
    };
    return column;
  }

  function setAllColumnDefaults(aColumn: ListColumn): ListColumn {
    return setColumnOnClick(setColumnDefaults(aColumn));
  }

  useEffect(() => {
    setColumns(props.columns.map(setColumnDefaults));
    setColumns((prevColumns) => prevColumns.map(setColumnOnClick));
  }, [props.columns]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setColumns((prevColumns) => prevColumns.map(setColumnOnClick));
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setItems(props.items);
  }, [props.items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.initialSortFieldName !== undefined) {
      const columnToSort = props.columns.find((x) => x.fieldName?.toLowerCase() === props.initialSortFieldName?.toLowerCase());
      if (columnToSort !== undefined) {
        sortColumn(columnToSort);

        if (props.initialSortDescending) {
          sortColumn(columnToSort);
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getKey = (item: ListRow): string => item.id;

  const copyAndSort = function _copyAndSort<T>(
    itemsToCopyAndSort: T[], column: ListColumn,
  ): T[] {
    return itemsToCopyAndSort.slice(0)
      .sort((a: T, b: T) => ((column.isSortedDescending ? getCellText(a, column) < getCellText(b, column) : getCellText(a, column) > getCellText(b, column)) ? 1 : -1));
  };

  function onColumnClick(column: ListColumn): void {
    sortColumn(column);
  }

  const sortColumn = (column: ListColumn) => {
    if (!items || column.isSortingDisabled) {
      return;
    }

    const newColumns: ListColumn[] = columns.slice();
    const currColumn: ListColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

    newColumns.forEach((newCol: ListColumn) => {
      /* eslint-disable no-param-reassign */
      if (newCol === currColumn) {
        newCol.isSortedDescending = newCol.isSorted && !newCol.isSortedDescending;
        newCol.isSorted = true;
        setAnnouncedMessage(`${newCol.name} is sorted ${newCol.isSortedDescending ? 'descending' : 'ascending'}`);
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
      /* eslint-enable no-param-reassign */
    });

    setColumns(newColumns);

    const newItems = copyAndSort(items, currColumn);
    setItems(newItems);
  };

  return (
    <div className={props.notScrollable ? notScrollableStyle : scrollableStyle}>
      <Announced message={items ? `${items.length} items are in the list` : 'no items in the list'} />
      {announcedMessage ? <Announced message={announcedMessage} /> : undefined}
      <DetailsList
        onShouldVirtualize={() => false}
        className={BasicListStyles}
        styles={props.styles}
        items={items || [] as ListRow[]}
        columns={columns}
        groups={props.groups}
        selectionMode={props.selectionMode || SelectionMode.none}
        getKey={getKey}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={false}
        onRenderRow={props.onRenderRow}
        onRenderItemColumn={props.onRenderItemColumn}
        compact={props.isCompactMode}
        groupProps={{
          showEmptyGroups: true,
          onRenderHeader: props.onRenderHeader,
        }}
        checkButtonAriaLabel="select row"
        selection={props.selection || undefined}
        onRenderCheckbox={props.onRenderCheckBox}
      />
    </div>
  );
};
