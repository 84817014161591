import {
  Link,
  mergeStyles,
} from '@fluentui/react';
import { openLinkInNewTab } from 'modules/links/links';
import { OrderBar } from 'components/orderBar/orderBar';
import React, {
  FunctionComponent, useContext,
} from 'react';
import { ConfigContext } from '../../components/configProvider/configContext';
import { PageNavigationAnchorNames } from '../pageNavigation/pageNavigation';

const wrapperStyles = mergeStyles({
  padding: '60px 130px 0',
});

const titleStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '40px',
  color: '#4C4C51',
  paddingBottom: '32px',
  textAlign: 'center',
});

const cardWrapperStyles = mergeStyles({
  display: 'flex',
});

const cardStyles = mergeStyles({
  marginRight: '41px',
  padding: '21px 23px',
  minHeight: '540px',
  width: '50%',
  maxWidth: '770px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  selectors: {
    ':last-child': {
      marginRight: '0',
    },
  },
});

const subtitleStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#4C4C51',
  paddingBottom: '32px',
});

const boldedSubtitleStyles = mergeStyles({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '24px',
  color: '#4C4C51',
  paddingBottom: '8px',
});

const learnMoreStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#323130',
});

const tableStyles = mergeStyles({
  paddingBottom: '32px',
});

const tableHeaderStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#323130',
  textAlign: 'left',
  paddingBottom: '12px',
  borderBottom: '1px solid #EDEBE9',
});

const tableRowLeftStyles = mergeStyles({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#323130',
  padding: '6px 32px 6px 0',
  borderBottom: '1px solid #EDEBE9',
});

const tableRowRightStyles = mergeStyles({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#605E5C',
  borderBottom: '1px solid #EDEBE9',
});

const horizontalRuleStyles = mergeStyles({
  borderTop: '1px solid #000',
});

const paragraphStyles = mergeStyles({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '24px',
  color: '#4C4C51',
  paddingBottom: '24px',
});

const linkStyles = mergeStyles({
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '600',
  selectors: {
    ':hover': {
      textDecoration: 'underline',
    },
    ':focus': {
      textDecoration: 'none',
    },
  },
});

const linkStyle = {
  root: {
    color: '#0078D4',
  },
};

export const CloudSupportServices: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);

  return (
    <>
      <section id={PageNavigationAnchorNames.CloudSupportServices} className={wrapperStyles}>
        <div className={titleStyles}>
          JWCC Cloud Support Offerings
        </div>
        <div className={cardWrapperStyles}>
          <div className={cardStyles}>
            <div className={subtitleStyles}>
              Cloud Support Base Packages
            </div>
            <div className={paragraphStyles}>
              Two packages are available so customers can choose the right option to provide comprehensive task order support for their unique requirements.
            </div>
            <div className={boldedSubtitleStyles}>
              Standard support
            </div>
            <div className={paragraphStyles}>
              Phone or portal and 24/7 online self-help access (documents, forums, videos, and MS Learn training site).
            </div>
            <div className={boldedSubtitleStyles}>
              Unified for Programs Support
            </div>
            <div className={paragraphStyles}>
              Enhanced support with assigned, expert resources to provide both proactive and reactive support with accelerated response times.
            </div>
          </div>
          <div className={cardStyles}>
            <div className={subtitleStyles}>
              A La Carte Support
            </div>
            <div className={paragraphStyles}>
              Many options are available. Customers can procure additional increments of support to accelerate mission outcomes.
              Specialty TE Support offerings and partner provided:
            </div>
            <table className={tableStyles}>
              <tbody>
                <tr className={horizontalRuleStyles}>
                  <th className={tableHeaderStyles} colSpan={2}>Types</th>
                </tr>
                <tr>
                  <td className={tableRowLeftStyles}>Remote and in-person training</td>
                  <td className={tableRowRightStyles}>Day increments (partner provided)</td>
                </tr>
                <tr>
                  <td className={tableRowLeftStyles}>Remote and in-person cloud advisory and assistance</td>
                  <td className={tableRowRightStyles}>Day increments (partner provided)</td>
                </tr>
                <tr>
                  <td className={tableRowLeftStyles}>Tactical edge support and training</td>
                  <td className={tableRowRightStyles}>Various increments (partner provided)</td>
                </tr>
                <tr>
                  <td className={tableRowLeftStyles}>Hourly remote and in-person advisory services across a range of LCATs</td>
                  <td className={tableRowRightStyles}>Microsoft Consulting Services</td>
                </tr>
              </tbody>
            </table>
            <div className={learnMoreStyles}>
              To learn more about the unique JWCC support service offerings, please
              &nbsp;
              <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.contactUsEndpoint || '')}>contact us</Link>
              .
            </div>
          </div>
        </div>
      </section>
      <OrderBar />
    </>
  );
};
