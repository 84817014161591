import {
  mergeStyles,
  NeutralColors,
  Image,
  ImageFit,
  Stack,
  Link,
} from '@fluentui/react';
import React, {
  FunctionComponent, useContext,
} from 'react';
import { openLinkInNewTab } from 'modules/links/links';
import { PageNavigationAnchorNames } from '../../components/pageNavigation/pageNavigation';
import whyJWCC from '../../assets/whyjwcc.png';
import { ConfigContext } from '../../components/configProvider/configContext';

const paddingWrapperStyles = mergeStyles({
  paddingTop: '60px',
});

const wrapperStyles = mergeStyles({
  paddingLeft: '126px',
  minHeight: '500px',
  backgroundColor: NeutralColors.black,
  position: 'relative',
});

const stackStyles = mergeStyles({
  maxWidth: '1253px',
  marginRight: '462px',
  position: 'relative',
  zIndex: 1,
});

const imageStyles = mergeStyles({
  position: 'absolute',
  height: '100%',
  top: 0,
  right: 0,
});

const titleStyles = mergeStyles({
  paddingTop: '55px',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '24px',
  color: '#F4F5F6',
  paddingBottom: '32px',
});

const paragraphStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#F4F5F6',
  paddingBottom: '32px',
});

const infoWrapperStyles = mergeStyles({
  display: 'flex',
  padding: '24px 0 48px',
});

const infoBulletStyles = mergeStyles({
  margin: 'auto',
  borderLeft: '4px solid #50E6FF',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  color: NeutralColors.white,
  paddingLeft: '8px',
  paddingRight: '32px',
});

const contactUsStyles = mergeStyles({
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '600',
  paddingBottom: '40px',
  selectors: {
    ':hover': {
      textDecoration: 'underline',
      color: '#0078D4',
    },
    ':focus': {
      textDecoration: 'none',
    },
  },
});

const linkStyle = {
  root: {
    color: '#50E6FF',
  },
};

export const WhyJWCC: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  return (
    <section id={PageNavigationAnchorNames.WhyJWCC} className={paddingWrapperStyles}>
      <div className={wrapperStyles}>
        <Stack className={stackStyles}>
          <Stack.Item className={titleStyles}>
            Why JWCC and Microsoft?
          </Stack.Item>
          <Stack.Item className={paragraphStyles}>
            JWCC provides a direct and streamlined path for DoD to acquire cloud services at all classification levels. With a continuum of compute from on-prem to the intelligent cloud and intelligent edge, Microsoft’s innovative suite of tools is available at discounted costs through JWCC. DoD customers have access to Microsoft managed services, tactical edge devices, professional services, customized training, and certifications to accelerate and help you do more for today’s mission and beyond.
          </Stack.Item>
          <Stack.Item className={paragraphStyles}>
            Through JWCC, Microsoft will deliver innovation to help DoD meet mission demands and at all data classifications by enabling:
          </Stack.Item>
          <Stack.Item className={infoWrapperStyles}>
            <div className={infoBulletStyles}>
              Data insights at the speed of relevance
            </div>
            <div className={infoBulletStyles}>
              Interoperability to support multi-cloud and hybrid cloud
            </div>
            <div className={infoBulletStyles}>
              Greater agility to meet mission demands
            </div>
            <div className={infoBulletStyles}>
              Unified cybersecurity to protect critical data and systems
            </div>
          </Stack.Item>
          <Stack.Item>
            <Link className={contactUsStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.contactUsEndpoint || '')}>Contact us to learn more</Link>
          </Stack.Item>
        </Stack>
        <Image className={imageStyles} imageFit={ImageFit.cover} src={whyJWCC} alt="Why JWCC Image" />
      </div>
    </section>
  );
};
