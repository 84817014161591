import { AzureThemeLight } from '@fluentui/azure-themes';
import { mergeStyles, ThemeProvider } from '@fluentui/react';
import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';
import { Route, Router } from 'react-router-dom';
import { LoginSelection } from 'components/LoginSelection/loginSelection';
import { OrderPage } from 'pages/orderForm/orderPage';
import { ServerConfig } from 'models/serverConfig';
import { PricingCalculator } from 'pages/pricingCalculator/pricingCalculator';
import { SkipNavContent, SkipToMain } from 'components/skipNav/skipToMain';
import { PricingCalculatorUserGuide } from 'pages/pricingCalculator/pricingCalculatorUserGuide';
import { TopNavBar } from './components/topNavBar/topNavBar';
import { Scrollable } from './components/scrollable/scrollable';
import { AuthProvider } from './components/authProvider/authProvider';
import { ConfigProvider } from './components/configProvider/configProvider';
import { HeroContentProvider } from './components/heroContentProvider/heroContentProvider';
import { MessageBar } from './components/messageBar/messageBar';
import { CenteredProgressDots } from './components/progressDots/progressDots';
import { ProtectedRoute } from './components/protectedRoute/protectedRoute';
import { LoadingState } from './models/loadingState';
import { initializeApplicationInfo } from './modules/config/appInfo';
import { getConfig, initializeConfig } from './modules/config/config';
import { getFailedToLoadUi } from './modules/loading/loading';
import { initializeLogging, logError } from './modules/logging/logging';
import { initializeMessageBar, showError, showWarning } from './modules/messageBar/messageBar';
import {
  accessDeniedRoute,
  contactUsRoute,
  defaultPricingCalculatorRoute,
  pricingCalculatorRoute,
  landingRoute,
  loginCallbackRoute,
  orderFormRoute,
} from './modules/routes/routes';
import { AccessDenied } from './pages/accessDenied/accessDenied';
import { LandingPage } from './pages/landing/landingPage';
import { LoginCallBack } from './pages/loginCallback/loginCallBack';
import { initializeAuth } from './modules/auth/auth';
import { ContactUsPage } from './pages/contactUs/contactUs';

mergeStyles({
  ':global(body)': {
    margin: 0,
  },
});

const themeProviderStyle = mergeStyles({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const browserHistory = createBrowserHistory();
let didInit = false;
let didInitConfig = false;

export const App = (): React.ReactElement => {
  const [appViewState, setAppViewState] = useState(LoadingState.NotLoaded);
  const [serverConfig, setServerConfig] = useState<ServerConfig>();
  const [authTenantId, setAuthTenantId] = useState<string | null>(localStorage.getItem('activeAuth'));

  useEffect(() => {
    if (serverConfig) {
      return;
    }

    const getServerConfig = async () => {
      await initializeConfig();
      const serverConfig = getConfig();
      setServerConfig(serverConfig);
    };
    getServerConfig();
  });

  useEffect(() => {
    if (!serverConfig) {
      return;
    }

    const redirectUri = sessionStorage.getItem('redirectUri');
    if (!redirectUri) {
      sessionStorage.setItem('redirectUri', window.location.href);
    }
    const initialize = async (authTenantId: string) => {
      try {
        await initializeAuth(authTenantId, serverConfig);
        await initializeApplicationInfo();
        initializeLogging();
        initializeMessageBar();

        if (serverConfig.dataHandlingMessage) {
          showWarning(serverConfig.dataHandlingMessage, 0, false);
        }

        setAppViewState(LoadingState.Loaded);
      } catch (error) {
        setAppViewState(LoadingState.Error);
        logError('Configurations failed to initialize', error);
        showError('There was an issue loading the page. Please refresh and try again.');
      }
    };

    const initializeConfigAndCheckAuthOptions = async () => {
      /*
       In non-prod environments, users are given the option to login with their @microsoft.com credentials or their Gov issued Common Access Card (CAC). This means that the auth configuration
       this means that the auth configuration changes depending on user input as well as environment configuration. Therefore, load the configuration first to determine which auth environments
       are available.

       When a user has chosen their preferred environment in the non-prod environment, the tenant is saved to local storage to persist through redirects caused by the process. On logout,
       this is cleared.

       In the case of unclassified PROD, only CAC is available, therefore, the tenantId can be immediately assigned and is not saved to local storage. This means that production users will never
       see the login environment options screen and will be shown the Login bar which will allow them to login with their CAC.
       */
      if (serverConfig.useCacAuth && !serverConfig.useInternalAuth) {
        setAuthTenantId(serverConfig.cacAzureAdAuthConfig?.tenantId || '');
      }
      didInitConfig = true;
    };

    if (!didInit && authTenantId !== null) {
      didInit = true;
      initialize(authTenantId);
    }

    if (!didInitConfig) {
      initializeConfigAndCheckAuthOptions();
    }

    if (serverConfig.isAgc) {
      setAuthTenantId('');
    }
  }, [authTenantId, serverConfig]);

  const getLoadedUi = () => (
    <>
      <ProtectedRoute exact path={landingRoute()} claims={[]}>
        <LandingPage />
      </ProtectedRoute>
      <ProtectedRoute exact path={orderFormRoute()} claims={[]}>
        <OrderPage />
      </ProtectedRoute>
      <ProtectedRoute exact path={contactUsRoute()} claims={[]}>
        <ContactUsPage />
      </ProtectedRoute>
      <ProtectedRoute exact path={defaultPricingCalculatorRoute()} claims={[]}>
        <PricingCalculator />
      </ProtectedRoute>
      <ProtectedRoute exact path={pricingCalculatorRoute()} claims={[]}>
        <PricingCalculatorUserGuide />
      </ProtectedRoute>
      <Route path={loginCallbackRoute()} component={LoginCallBack} />
      <Route path={accessDeniedRoute()} component={AccessDenied} />
    </>
  );

  const getUiFromState = () => {
    switch (appViewState) {
      case LoadingState.Loaded:
        // eslint-disable-next-line no-console
        console.dir('loaded');
        return getLoadedUi();
      case LoadingState.Error:
        // eslint-disable-next-line no-console
        console.dir('failed to load');
        return getFailedToLoadUi();
      case LoadingState.NotLoaded:
      case LoadingState.Loading:
      default:
        return <CenteredProgressDots />;
    }
  };

  const getNotProtectedUx = () => (
    <ThemeProvider className={themeProviderStyle} theme={AzureThemeLight}>
      <Router history={browserHistory}>
        <ConfigProvider serverConfig={serverConfig}>
          <HeroContentProvider>
            <Scrollable>
              <SkipToMain />
              <TopNavBar />
              <MessageBar />
              <SkipNavContent />
              {getUiFromState()}
            </Scrollable>
          </HeroContentProvider>
        </ConfigProvider>
      </Router>
    </ThemeProvider>
  );

  const getUx = () => {
    if (serverConfig?.isAgc) {
      return getNotProtectedUx();
    }

    if (authTenantId === null) {
      return (<LoginSelection setActiveAuthTenant={setAuthTenantId} serverConfig={serverConfig} />);
    }

    return (
      <AuthProvider>{getNotProtectedUx()}</AuthProvider>
    );
  };

  return getUx();
};
