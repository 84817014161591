import React, { FunctionComponent, useState } from 'react';
import { PageNavigator } from './pageNavigator';
import { PageContent } from './pageContent';
import { AdditionalSupport } from './additionalSupport';

export interface SupportingPagesProps {
  defaultPath?: string;
}

export const SupportingPages: FunctionComponent<SupportingPagesProps> = ({ defaultPath }) => {
  const [currentPath, setCurrentPath] = useState<string>(defaultPath || '');

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 5fr 1fr',
          gridColumnGap: '10px',
          minHeight: '40vh',
          padding: '60px 128px 32px 127px',
        }}
      >
        <PageNavigator currentPath={currentPath} setCurrentPath={setCurrentPath} />
        <PageContent currentPath={currentPath} />
        <AdditionalSupport />
      </div>
    </>

  );
};
