import {
  mergeStyles,
  Link,
} from '@fluentui/react';
import { openLinkInNewTab } from 'modules/links/links';
import React, { FunctionComponent } from 'react';

const cardStyles = mergeStyles({
  width: '500px',
  minHeight: '140px',
  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
  borderRadius: '2px',
  display: 'flex',
  marginRight: '76px',
  marginBottom: '41px',
  selectors: {
    ':last-child': {
      marginRight: '0',
    },
  },
});

const cardNumberStyles = mergeStyles({
  minWidth: '100px',
  maxWidth: '180px',
  height: '100%',
  backgroundColor: '#243A5E',
  color: '#50E6FF',
  fontSize: '42px',
  margin: 'auto 0',
  fontWeight: 600,
  display: 'flex',
});

const cardNumberTextStyles = mergeStyles({
  margin: 'auto',
});

const cardTextStyles = mergeStyles({
  maxWidth: '200px',
  lineHeight: '20px',
  padding: '12px',
  fontSize: '14px',
  fontWeight: '600',
});

const linkStyles = mergeStyles({
  paddingTop: '12px',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '400',
  selectors: {
    ':hover': {
      textDecoration: 'underline',
    },
    ':focus': {
      textDecoration: 'none',
    },
  },
});

const linkStyle = {
  root: {
    color: '#0078D4',
  },
};
export interface InfoCardProps {
  text: string,
  cardNumber: string,
  link?: string,
  linkText?: string,
}

export const HowToCard: FunctionComponent<InfoCardProps> = (props) => {
  const {
    text,
    cardNumber,
    link,
    linkText,
  } = props;

  return (
    <div className={cardStyles}>
      <div className={cardNumberStyles}>
        <div className={cardNumberTextStyles}>
          {cardNumber}
        </div>
      </div>
      <div className={cardTextStyles}>
        <div>
          {text}
        </div>
        {link && linkText && (
          <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(link)}>{linkText}</Link>
        )}
      </div>
    </div>
  );
};
