import React, { FunctionComponent } from 'react';
import { NeutralColors } from '@fluentui/theme';
import { mergeStyles, Stack } from '@fluentui/react';
import { renderToStaticMarkup } from 'react-dom/server';
import { ReactComponent as CloudTearIcon } from './CloudTearIcon.svg';

const topLevelStyles = mergeStyles({
  backgroundColor: '#0078D4',
  backgroundImage: `url('data:image/svg+xml;utf8,${encodeURIComponent(renderToStaticMarkup(<CloudTearIcon />))}')`,
  backgroundPosition: 'right top',
  backgroundRepeat: 'no-repeat',
  backgroundOrigin: 'content-box',
  color: NeutralColors.white,
  padding: 100,
});

const titleStyles = mergeStyles({
  fontSize: 72,
});

const introStyles = mergeStyles({
  fontSize: 25,
});

const featureStyles = mergeStyles({
  fontSize: 18,
});

interface AccessDeniedProps {
  accessMessage?: string,
}

export const AccessDenied: FunctionComponent<AccessDeniedProps> = (props) => {
  const { accessMessage } = props;

  return (
    <Stack className={topLevelStyles} verticalFill gap={12}>
      <Stack.Item className={titleStyles}>
        Hmmm...
      </Stack.Item>
      <Stack.Item className={introStyles}>
        Looks like you don&apos;t have access
      </Stack.Item>
      <Stack.Item className={featureStyles}>
        {accessMessage || 'You do not have permissions to view this page.'}
      </Stack.Item>
    </Stack>
  );
};
