import {
  Link,
  mergeStyles,
} from '@fluentui/react';
import { openLinkInNewTab } from 'modules/links/links';
import React, {
  FunctionComponent, useContext,
} from 'react';
import { ConfigContext } from '../../components/configProvider/configContext';

const wrapperStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F4F5F6',
  padding: '48px 18px 0',
  marginTop: 'auto',
});

const footerLinksStyles = mergeStyles({
  display: 'flex',
  marginLeft: 'auto',
});

const linkStyles = mergeStyles({
  color: '#1a1a1f',
  paddingRight: '16px',
  paddingBottom: '12px',
});

const linkStyle = {
  root: {
    color: '#1a1a1f',
  },
};

const microsoftStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '18px',
});

export const Footer: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  const isAgc = configContext.serverConfig?.isAgc;

  const link = (text: string, endpoint?: string, isHidden?: boolean) => {
    let localLinkStyles = linkStyles;
    if (isHidden) {
      localLinkStyles = mergeStyles(linkStyles, {
        display: 'none',
      });
    }
    return (
      <Link disabled={isHidden} className={localLinkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(endpoint || '')}>{text}</Link>);
  };

  return (
    <div className={wrapperStyles}>
      <div className={footerLinksStyles}>
        {link('Diversity and Inclusion', configContext.serverConfig?.diversityInclusionEndpoint, isAgc)}
        {link('Accessibility', configContext.serverConfig?.accessibilityEndpoint, isAgc)}
        {link('Privacy & Cookies', configContext.serverConfig?.privacyCookiesEndpoint, false)}
        {link('Data Protection Notice', configContext.serverConfig?.dataProtectionNoticeEndpoint, isAgc)}
        {link('Trademarks', configContext.serverConfig?.trademarksEndpoint, false)}
        {link('Terms of use', configContext.serverConfig?.termsOfUseEndpoint, false)}
      </div>
      <div className={footerLinksStyles}>
        {link('Privacy Data Management ', configContext.serverConfig?.privacyDataManagementEndpoint, isAgc)}
        {link('Contact us', configContext.serverConfig?.contactUsEndpoint, isAgc)}
        <div className={microsoftStyles}>© Microsoft 2023</div>
      </div>
    </div>
  );
};
