import { ServerConfig } from 'models/serverConfig';
import { PageNavigationAnchorNames, PageNavigationItemNames } from './pageNavigation';

export const getSectionName = (anchorId: PageNavigationAnchorNames): PageNavigationItemNames => {
  switch (anchorId) {
    case PageNavigationAnchorNames.Overview:
      return PageNavigationItemNames.Overview;
    case PageNavigationAnchorNames.WhyJWCC:
      return PageNavigationItemNames.WhyJWCC;
    case PageNavigationAnchorNames.GettingStarted:
      return PageNavigationItemNames.GettingStarted;
    case PageNavigationAnchorNames.CloudSupportServices:
      return PageNavigationItemNames.CloudSupportServices;
    case PageNavigationAnchorNames.FAQs:
      return PageNavigationItemNames.FAQs;
    default:
      return PageNavigationItemNames.Overview;
  }
};

export const getExternalPricingCalculatorLink = (serverConfig : ServerConfig | undefined) : string => `${serverConfig?.externalPricingCalculatorEndpoint}/?billingAccount=${serverConfig?.billingAccount}&billingProfile=${serverConfig?.billingProfile}`;
