import {
  IDetailsListStyles,
  IDetailsRowProps,
  IDetailsRowStyles,
  IGroupDividerProps,
  IGroupHeaderProps,
  IGroupHeaderStyles,
  IRenderFunction,
  Link,
  mergeStyles, SelectionMode,
} from '@fluentui/react';
import {
  GroupedList, ListColumn, ListGroup, ListRow,
} from 'components/groupedList/groupedList';
import { openLinkInNewTab } from 'modules/links/links';
import React, {
  FunctionComponent, useContext,
} from 'react';
import { ConfigContext } from '../../components/configProvider/configContext';
import { PageNavigationAnchorNames } from '../pageNavigation/pageNavigation';

const wrapperStyles = mergeStyles({
  padding: '60px 130px 0',
  display: 'flex',
});

const titleStyles = mergeStyles({
  width: '40%',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '40px',
  color: '#4C4C51',
  paddingBottom: '32px',
  minWidth: '400px',
});

const faqsStyles = mergeStyles({
  borderTop: '1px solid #EDEBE9',
  width: '60%',
  minWidth: '400px',
});

const groupedListStyle: Partial<IDetailsListStyles> = {
  root: {
    overflow: 'hidden',
  },
  contentWrapper: {
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: '600',
    color: '#242424',
  },
};

const paragraphStyles = mergeStyles({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#4C4C51',
  paddingBottom: '24px',
  marginTop: '32px',
});

const linkStyles = mergeStyles({
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '600',
  selectors: {
    ':hover': {
      textDecoration: 'underline',
    },
    ':focus': {
      textDecoration: 'none',
    },
  },
});

const linkStyle = {
  root: {
    color: '#0078D4',
  },
};

interface AnswerListItem extends ListRow {
  id: string,
  answer: string,
  contactLink?: boolean,
  catalogLink?: boolean,
  gettingStartedLink?: boolean,
}

export const FAQs: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);

  const navigateTo = (anchor: string) => {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const createGroup = (key: string, name: string, startIndex: number): ListGroup => ({
    key,
    name,
    startIndex,
    count: 1,
    isCollapsed: true,
    isDropEnabled: false,
  });
  const groups: ListGroup[] = [
    createGroup('1', 'How can I learn more about getting started with Microsoft and JWCC Cloud Services?', 0),
    createGroup('2', 'Who is eligible to use JWCC?', 1),
    createGroup('3', 'How do I initiate a task order on JWCC?', 2),
    createGroup('4', 'What Microsoft services are available as part of JWCC Cloud Services?', 3),
    createGroup('5', 'What classification levels will be supported on JWCC Cloud Services?', 4),
    createGroup('6', 'How does JWCC differ from other contract vehicles available to the DoD?', 5),
    createGroup('7', 'Do Microsoft services available on JWCC Cloud Services meet my compliance requirements?', 6),
    createGroup('8', 'Does Microsoft provide training as part of JWCC Cloud Services?', 7),
    createGroup('9', 'Does Microsoft provide professional services as a part of JWCC Cloud Services?', 8),
    createGroup('10', 'Can Microsoft help with my cloud migration as part of JWCC Cloud Services?', 9),
  ];

  const columns: ListColumn[] = [{
    key: 'answer',
    name: '',
    minWidth: 200,
    onRender: (userItem: AnswerListItem) => (
      <>
        {userItem.answer}
        {userItem.contactLink && (
          <>
            <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.contactUsEndpoint || '')}>contact us</Link>
            .
          </>
        )}
        {userItem.catalogLink && (
          <>
            <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.catalogEndpoint || '')}>Microsoft JWCC Catalog</Link>
            .
          </>
        )}
        {userItem.gettingStartedLink && (
          <>
            <Link className={linkStyles} styles={linkStyle} onClick={() => navigateTo(PageNavigationAnchorNames.GettingStarted)}>JWCC Cloud Support Offerings</Link>
            &nbsp;section above.
          </>
        )}
      </>
    ),
  }];

  const answers: AnswerListItem[] = [
    { id: '1', answer: 'To learn more about how Microsoft can help address your specific mission requirements, please ', contactLink: true },
    { id: '2', answer: 'JWCC is available for any information technology mission owner within the DoD.' },
    { id: '3', answer: 'Direct awards for cloud services will be made to cloud service providers (CSPs) through task orders. To initiate the task order process, please work with your organization’s contracting officer. DoD customers can submit a task order solicitation through the Defense Information Technology Contracting Organization (DITCO) or their own contracting office. Review the ', gettingStartedLink: true },
    { id: '4', answer: 'Through Microsoft service offering on JWCC, DoD mission owners are now able to leverage compute, networking, storage, database, AI and ML services along with cloud support, professional and migration services, and tactical edge devices. Microsoft has the most cloud services for Government than any other CSP with more than 170 DoD IL2 and 150+ DoD IL4/IL5 services.​ The full suite of cloud services available are in the ', catalogLink: true },
    { id: '5', answer: 'Microsoft currently offers services at all three classification levels (Unclassified, Secret, and Top Secret).' },
    { id: '6', answer: 'JWCC offers a path to contract directly with Microsoft to use a variety of services at all classification levels (Unclassified, Secret, and Top Secret) to include professional services and tactical edge. To learn more, please ', contactLink: true },
    { id: '7', answer: 'The Microsoft services currently being offered on JWCC are purpose-built to meet your compliance requirements, ranging from DoD IL2 to ICD 503, 507 and JSIG (PL-3).' },
    { id: '8', answer: 'To further support the digital transformation of mission, Microsoft stands ready to support and train the U.S. defense workforce with Azure cloud and cybersecurity training and certifications ranging from foundational to expert level and tailored to various roles. Training is provided in onsite and virtual formats, self-paced and instructor-led. With 225+ learning paths, 1,000+ modules, and localized in dozens of languages, there\'s a learning path and modality for any learner. Microsoft\'s expansive learning portfolio consists of both paid and no-additional-cost offerings.' },
    { id: '9', answer: 'Microsoft offers a variety of cloud support services that are aligned to DoD users\' needs for the successful execution of task orders and mission requirements. To learn more about the unique JWCC Support Service offerings, review the ', gettingStartedLink: true },
    { id: '10', answer: 'Through JWCC, Microsoft provides Cloud Solution Architects at no additional cost to support your mission from inception to execution. To connect with our Cloud Solution Architects, please ', contactLink: true },
  ];

  const onRenderHeader = (headerProps: IGroupDividerProps, defaultRender: IRenderFunction<IGroupHeaderProps>) => {
    const headerStyle: Partial<IGroupHeaderStyles> = {
      headerCount: {
        display: 'none',
      },
      title: {
        whiteSpace: 'break-word',
      },
      root: {
        padding: '8px 0',
        borderBottom: '1px solid #EDEBE9',
        overflow: 'auto',
      },
    };

    return (
      <span>
        {defaultRender({ ...headerProps, styles: headerStyle })}
      </span>
    );
  };

  const onRenderRow = (onRenderRowProps: IDetailsRowProps | undefined, defaultRender: IRenderFunction<any>) => {
    const headerCountStyle: Partial<IDetailsRowStyles> = {
      root: {
        whiteSpace: 'unset',
        borderBottom: 'none !important',
        overflow: 'hidden',
      },
      isRowHeader: {
        textOverflow: 'unset',
        whiteSpace: 'unset',
      },
    };
    return (
      <span>
        {defaultRender({
          ...onRenderRowProps,
          styles: headerCountStyle,
        })}
      </span>
    );
  };

  return (
    <section id={PageNavigationAnchorNames.FAQs} className={wrapperStyles}>
      <div className={titleStyles}>
        Frequently asked questions about JWCC
      </div>
      <div className={faqsStyles}>
        <GroupedList
          items={answers}
          columns={columns}
          groups={groups}
          isCompactMode={false}
          selectionMode={SelectionMode.none}
          onRenderHeader={onRenderHeader}
          onRenderRow={onRenderRow}
          styles={groupedListStyle}
        />
        <div className={paragraphStyles}>
          Microsoft provides DoD customers access to exclusive JWCC pricing.
          <br />
          For more information you can access our
          &nbsp;
          <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.internalPricingCalculatorEndpoint || '')}>pricing calculator</Link>
          &nbsp;
          or
          &nbsp;
          <Link className={linkStyles} styles={linkStyle} onClick={() => openLinkInNewTab(configContext.serverConfig?.contactUsEndpoint || '')}>contact us</Link>
          .
        </div>
      </div>
    </section>
  );
};
