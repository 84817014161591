import { mergeStyles } from '@fluentui/react';
import { ConfigContext } from 'components/configProvider/configContext';
import React, { FunctionComponent, useContext } from 'react';
import { RedirectButton } from '../button/redirectButton';

const orderBannerStyles = mergeStyles({
  marginLeft: '4.5em',
  marginRight: '4.5em',
  marginTop: '80px',
  height: '70px',
  color: '#50E6FF',
  backgroundColor: '#243A5E',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  position: 'relative',
  textAlign: 'center',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '70px',
  display: 'flex',
  justifyContent: 'center',
  minWidth: '256px',
  overflow: 'auto',
});

const leftBorderStyles = mergeStyles({
  position: 'absolute',
  height: '70px',
  width: '6px',
  top: '0',
  left: '0',
  background: 'linear-gradient(180deg, #4EE6FF 0%, #007CD7 100%)',
});

const orderNowStyles = mergeStyles({
  borderRadius: '4px',
  backgroundColor: '#50E6FF',
  color: '#323130',
  fontSize: '14px',
  lineHeight: '20px',
  padding: '14px 31px !important',
  margin: 'auto 0 auto 24px',
  selectors: {
    ':hover': {
      backgroundColor: '#50E6FF',
      textDecoration: 'underline',
      color: '#323130',
    },
    ':focus': {
      color: '#323130 !important',
      backgroundColor: '#50E6FF !important',
      textDecoration: 'none',
    },
    ':focus::after': {
      outlineColor: '#323130 !important',
    },
    ':active': {
      color: '#323130',
      backgroundColor: '#50E6FF',
      textDecoration: 'none',
    },
  },
});

export const OrderBar: FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  const orderFormUrl = configContext.serverConfig?.orderFormEndpoint;
  const orderFormEndpoint = `/${orderFormUrl?.split('/').pop()}`;

  return (
    (!configContext.serverConfig?.isAgc)
      ? (
        <div className={orderBannerStyles}>
          <div className={leftBorderStyles} />
          Order JWCC support service offerings today.
          <RedirectButton
            text="Order now"
            styles={orderNowStyles}
            endpoint={orderFormEndpoint || ''}
          />
        </div>
      )
      : (<></>)
  );
};
