import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  ITextFieldStyles, Link, mergeStyles, TextField,
} from '@fluentui/react';
import { sections, SupportServiceSection } from 'data/supportServices';
import { ExpandableList } from '../../components/expandableList/expandableList';
import { BasicList, ListColumn } from '../../components/basicList/basicList';

const wrapperStyles = mergeStyles({
  padding: '55px 90px 0',
});

const fieldStyle: Partial<ITextFieldStyles> = {
  field: {
    selectors: {
      '::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
};

const instructionsStyles = mergeStyles({
  paddingBottom: '22px',
  fontSize: '16px',
  lineHeight: '24px',
});

const linkStyles = mergeStyles({
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '600',
  selectors: {
    ':hover': {
      textDecoration: 'underline',
    },
    ':focus': {
      textDecoration: 'none',
    },
  },
});

const linkStyle = {
  root: {
    color: '#0078D4',
  },
};
export interface SupportService {
  id: string,
  productId: string,
  skuId: string,
  title: string,
  unitOfIssue: string,
  quantity?: number,
}

interface SupportServicesProps {
  onQuantityChanged?(itemsOrdered: SupportServicesOrder[]): void,
  supportServicesOrder?: SupportServicesOrder[],
  isDisabled?: boolean,
}

export interface SupportServicesOrder {
  productId: string,
  skuId: string,
  description: string,
  unit: string,
  quantity?: number,
}

export const SupportServices: FunctionComponent<SupportServicesProps> = (props) => {
  const { onQuantityChanged, supportServicesOrder, isDisabled } = props;
  const [supportServices, setSupportServices] = useState<SupportServicesOrder[]>(supportServicesOrder || []);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [filteredSections, setFilteredSections] = useState<SupportServiceSection[]>([]);
  const [currentSectionData, setCurrentSectionData] = useState<SupportServiceSection[]>(sections);

  useEffect(() => {
    if (supportServicesOrder) {
      setSupportServices(supportServicesOrder);
    }
  }, [supportServicesOrder]);
  const onQuantityChange = (supportService: SupportService, quantity?: number) => {
    if (!onQuantityChanged) {
      return;
    }

    const copySupportServices = [...supportServices];
    const index = supportServices.findIndex((service) => service.productId === supportService.productId && service.skuId === supportService.skuId);
    if (index !== -1) {
      // If the support service was found, but the quantity is undefined, remove it from the array
      if (!quantity || quantity === 0) {
        copySupportServices.splice(index, 1);

        // Also find it in the sections and remove the quantity or it will persist
        const copyCurrentSectionData = [...currentSectionData];
        copyCurrentSectionData.forEach((section, sectionIndex) => {
          const itemIndex = section.content.findIndex((item) => item.productId === supportService.productId && item.skuId === supportService.skuId);

          if (itemIndex !== -1) {
            currentSectionData[sectionIndex].content[itemIndex].quantity = undefined;
          }
        });

        setCurrentSectionData(copyCurrentSectionData);
      } else {
        // Otherwise just update the quantity
        copySupportServices[index].quantity = quantity;
      }
    } else {
      copySupportServices.push({
        productId: supportService.productId,
        skuId: supportService.skuId,
        description: supportService.title,
        unit: supportService.unitOfIssue,
        quantity,
      });
    }
    setSupportServices(copySupportServices);
    onQuantityChanged(copySupportServices);
  };

  const columns: ListColumn[] = [
    {
      key: 'productId',
      name: 'Product ID',
      fieldName: 'productId',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: 'skuId',
      name: 'SkuId',
      fieldName: 'skuId',
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: 'title',
      name: 'Title/Description',
      fieldName: 'title',
      minWidth: 200,
    },
    {
      key: 'unitOfIssue',
      name: 'Unit of Issue',
      fieldName: 'unitOfIssue',
      minWidth: 100,
    },
    {
      key: 'quanity',
      name: 'Quantity',
      fieldName: 'quanity',
      onRender: (supportService: SupportService) => (
        isDisabled ? (
          <span>{supportService.quantity}</span>
        ) : (
          <TextField
            styles={fieldStyle}
            type="number"
            title="Enter quantity"
            defaultValue={supportService.quantity?.toString()}
            onChange={(event: any, newValue?: string) => onQuantityChange(supportService, Number(newValue))}
          />
        )
      ),
      minWidth: 75,
    },
  ];

  const onRenderItem = (item: SupportServiceSection) => (
    <BasicList
      items={item.content}
      columns={columns}
    />
  );

  const onRenderHeader = (item: SupportServiceSection) => (
    <>
      {item.header}
    </>
  );

  const expandAll = () => {
    // If the user already selected expand all once, we need to reset it to false so the next time they click it, it will propogate
    if (isExpanded) {
      setIsExpanded(false);
      setTimeout(() => {
        setIsExpanded(true);
      });
    } else {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (!supportServicesOrder || !isDisabled) {
      return;
    }

    const filteredSection: SupportServiceSection[] = [];

    // Iterate over all of the sections
    // Find all items that it contains
    sections.forEach((section) => {
      const content: SupportService[] = [];

      // Iterate over all of the support services
      supportServicesOrder.forEach((supportService) => {
        const supportItem = section.content.find((item) => item.skuId === supportService.skuId && item.productId === supportService.productId);
        if (supportItem) {
          supportItem.quantity = supportService.quantity;
          content.push(supportItem);
        }
      });

      if (content.length > 0) {
        const updatedSection = { ...section };
        updatedSection.content = content;
        filteredSection.push(updatedSection);
      }
    });

    setFilteredSections(filteredSection);
  }, [supportServicesOrder, isDisabled]);

  return (
    <div className={!isDisabled ? wrapperStyles : undefined}>
      {!isDisabled && (
        <div className={instructionsStyles}>
          Select the type of support services you need from the five packages below.
          &nbsp;
          &nbsp;
          <Link className={linkStyles} styles={linkStyle} onClick={expandAll}>Expand all</Link>
        </div>
      )}
      <ExpandableList
        items={isDisabled ? filteredSections : currentSectionData}
        onRenderItem={onRenderItem}
        onRenderHeader={onRenderHeader}
        isExpanded={isDisabled ? true : isExpanded}
        isReadOnly={isDisabled}
        styles={isDisabled ? {
          accordionHeader: {
            padding: '10px 0 0',
            alignItems: 'stretch',
            flexGrow: 1,
            fontWeight: 600,
            fontSize: '20px',
            ':focus': {
              outline: 'none',
              border: '1px solid black',
            },
          },
        } : {
          accordion: {
            marginBottom: '1px',
          },
          accordionHeader: {
            padding: '10px',
            backgroundColor: '#F4F5F6',
            alignItems: 'stretch',
            flexGrow: 1,
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px',
            ':focus': {
              outline: 'none',
              border: '1px solid black',
            },
          },
        }}
      />
    </div>
  );
};
