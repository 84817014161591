export const isEmailValid = (email: string | undefined): boolean => {
  if (!email) {
    return false;
  }

  // Regex to allow any characters except @ in the email name and domain and only alphabet after the .
  const emailRegex = new RegExp(/^[^@\s]+@[^@\s]+\.[A-Z]{2,}$/i);
  return emailRegex.test(email);
};

export const isPhoneNumberValid = (phoneNumber: string | undefined): boolean => {
  if (!phoneNumber || !isRequiredTextInputValid(phoneNumber)) {
    return false;
  }

  // strip all non-numeric characters. A valid US phone number will have 10 digits. Since this is for government usage, we are assuming US phone numbers only.
  const USAPhoneNumberOfDigits = 10;
  // Regex to strip all non-number chars from the string.
  const strippedPhoneNumber = phoneNumber.replace(/\D/g, '');
  return strippedPhoneNumber.length === USAPhoneNumberOfDigits;
};

export const isRequiredTextInputValid = (text: string | undefined): boolean => {
  if (!text || text.trim() === '') {
    return false;
  }

  return true;
};

export const isRequiredTextArrayValid = (textItems: string[] | undefined): boolean => {
  if (!textItems || textItems.length === 0) {
    return false;
  }

  return true;
};

// Removes chars '(', ')', and '-' from the phone number string
export const removeNonNumberCharacters = (phoneNumber: string): string => phoneNumber.replace(/([()-])/g, '');
