import { AccountInfo } from '@azure/msal-browser';
import { createContext } from 'react';

export interface AuthContextProps {
  signin: (tenantId?: string) => Promise<void>,
  signinRedirectCallback: () => Promise<boolean>,
  signout: () => Promise<void>,
  isAuthenticated: () => Promise<boolean>,
  getUser: () => Promise<AccountInfo | null>,
}

export const AuthContext = createContext<AuthContextProps>({
  signin: (): Promise<void> => new Promise<void>(() => undefined),
  signinRedirectCallback: (): Promise<boolean> => new Promise<boolean>(() => false),
  signout: (): Promise<void> => new Promise<void>(() => undefined),
  isAuthenticated: (): Promise<boolean> => new Promise<boolean>(() => false),
  getUser: (): Promise<AccountInfo | null> => new Promise<AccountInfo | null>(() => null),
});

export const AuthConsumer = AuthContext.Consumer;
